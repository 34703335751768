import React from 'react';
import { Redirect } from 'react-router-dom';

import { useWorkspaces } from './WorkspaceProvider';

const WorkspaceRedirect: React.FC = () => {
  const { ACTIVE_WORKSPACE_NAME } = useWorkspaces();

  return <Redirect to={`/orchestrator/${ACTIVE_WORKSPACE_NAME || 'workspaces'}`} />;
};

export default WorkspaceRedirect;
