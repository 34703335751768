import { IStyle } from '@fluentui/react';

export interface IExecutorPoolDetailStyles {
  footerButton: IStyle;
  editorButton: IStyle;
  dialogInput: IStyle;
  form: IStyle;
  expandedContentItem: IStyle;
  editorContainer: IStyle;
  tooltip: IStyle;
  infoLabel: IStyle;
}

export const executorPoolDetailStyles: Partial<IExecutorPoolDetailStyles> = {
  footerButton: {
    marginTop: 8,
    marginRight: 4,
  },
  editorButton: {
    marginTop: 4,
    marginBottom: 4,
  },
  dialogInput: {
    width: '30%',
    minWidth: 260,
    maxWidth: 360,
    marginRight: 12,
  },
  editorContainer: {
    display: 'flex',
    width: '30%',
    minWidth: 260,
    maxWidth: 360,
    flexGrow: 1,
    minHeight: 300,
    maxHeight: 350,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
  },
  expandedContentItem: {
    flexWrap: 'nowrap',
    display: 'flex',
  },
  tooltip: {
    padding: 4,
  },
  infoLabel: {
    display: 'flex',
    alignItems: 'center',
  },
};
