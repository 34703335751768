import { IColumn, IStyle } from '@fluentui/react';
import {
  DetailsList,
  IDetailsListProps,
  ListRow,
  Loader,
  WidgetPanel,
  detailsListStylesCard,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';

type Props = {
  title?: string;
  columns: IColumn[];
  items?: unknown[];
  loading: boolean;
  NoItemsContent?: JSX.Element;
  ErrorContent?: JSX.Element;
  delayLoader?: boolean;
};

interface IWidgetListStyles {
  detailsListContainer: IStyle;
  noRunnablesContainer: IStyle;
}

const widgetListStyles: Partial<IWidgetListStyles> = {
    detailsListContainer: {
      height: '100%',
      overflow: 'auto',
    },
    noRunnablesContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  },
  widgetStyles = {
    controls: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    header: {
      padding: 0,
      paddingBottom: 16,
    },
    root: {
      background: 'transparent',
      borderRadius: 'none',
      padding: 20,
      maxWidth: '100%',
    },
    title: {
      fontSize: 16,
      lineHeight: '20px',
    },
  };

const WidgetList = ({ title, columns, items, loading, NoItemsContent, ErrorContent, delayLoader = true }: Props) => {
  const classNames = useClassNames<IWidgetListStyles, ClassNamesFromIStyles<IWidgetListStyles>>(
      'widgetList',
      widgetListStyles
    ),
    theme = useTheme(),
    hasSomeItem = !loading && items && items.length > 0,
    timeoutRef = React.useRef<number>(),
    [showLoader, setShowLoader] = React.useState<boolean>(!delayLoader),
    listProps: Partial<IDetailsListProps> = {
      styles: detailsListStylesCard,
      focusZoneProps: { style: { width: '100%' } },
      isHeaderVisible: false,
      cellStyleProps: {
        cellExtraRightPadding: 0,
        cellLeftPadding: 0,
        cellRightPadding: 0,
      },
      onRenderRow: (props?: { item: { [key: string]: any }; columns?: IColumn[] }) => {
        if (!props) return null;
        const { item, columns = [] } = props;
        return (
          <ListRow
            data={item}
            columns={columns}
            styles={{
              row: {
                '&:hover': {
                  background: theme.semanticColors?.contentBackground,
                  '.h2o-ListCell-text': {
                    color: theme.semanticColors?.textSecondary,
                  },
                },
              },
            }}
          />
        );
      },
    };

  React.useEffect(() => {
    if (delayLoader) {
      if (loading) {
        timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
      } else {
        setShowLoader(false);
        window.clearTimeout(timeoutRef.current);
      }
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading]);

  return (
    <>
      {hasSomeItem && (
        <WidgetPanel title={title} styles={widgetStyles}>
          <div className={classNames.detailsListContainer}>
            <DetailsList {...listProps} columns={columns} items={items} />
          </div>
        </WidgetPanel>
      )}
      <div className={classNames.noRunnablesContainer}>
        {loading ? (
          showLoader ? (
            <Loader label="Loading..." />
          ) : null
        ) : items !== undefined ? (
          items.length === 0 && (NoItemsContent || <div>No items found. Start by creating one.</div>)
        ) : (
          ErrorContent || <div>Failed to load items.</div>
        )}
      </div>
    </>
  );
};

export default WidgetList;
