import { IStyle, PanelType } from '@fluentui/react';
import { Dropdown, KeyValuePairEditor, Panel, Search, TextField, WidgetItem, useClassNames } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';
import { DropdownOption } from './RunnableDetail';
import { WidgetIconButton } from './Runnables';

export interface IWorkflowCanvasPanel {
  isPanelOpen: boolean;
  activeNode: any;
  stepType: string;
  activeNodeSelectedItem: any;
  showValidation: boolean;
  searchItems: any[];
  readOnly?: boolean;
  onCloseSidebar: () => void;
  onItemClick: (item: any) => void;
  onDropdownChange: (event: React.FormEvent<HTMLDivElement>, item?: any) => void;
  removeSelectedItem: () => void;
  onSearchChange: (searchText: string) => void;
  onUpdateConfig: (config: any) => void;
  onStepNameChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
}

export type RowData = {
  id: string;
  title?: string;
  creator?: string;
  creatorDisplayName?: string;
  createdAt?: string;
  onTitleChange: (newTitle: string) => void;
  showValidation: boolean;
  readOnly?: boolean;
};

interface IWorkflowCanvasPanelStyles {
  placeholderContainer: IStyle;
}

const workflowCanvasPanelStyles: IWorkflowCanvasPanelStyles = {
  placeholderContainer: {
    height: 64, // Widget height.
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
};

const dropdownOptions: DropdownOption[] = [
  { key: 'runnable', text: 'Runnable' },
  { key: 'workflow', text: 'Workflow' },
];

const WorkflowCanvasPanel = ({
  onCloseSidebar,
  isPanelOpen,
  activeNode,
  stepType,
  onDropdownChange,
  activeNodeSelectedItem,
  showValidation,
  searchItems,
  readOnly,
  onItemClick,
  removeSelectedItem,
  onSearchChange,
  onUpdateConfig,
  onStepNameChange,
}: IWorkflowCanvasPanel) => {
  const classNames = useClassNames<IWorkflowCanvasPanelStyles, ClassNamesFromIStyles<IWorkflowCanvasPanelStyles>>(
    'workflowCanvasPanel',
    workflowCanvasPanelStyles
  );

  return (
    <Panel
      hasCloseButton
      customWidth={'500px'}
      type={PanelType.custom}
      isFooterAtBottom={false}
      // Prevents canvas from layout jump on open.
      isHiddenOnDismiss
      onDismiss={onCloseSidebar}
      isOpen={isPanelOpen}
      isBlocking={false}
      style={{ zIndex: 5 }}
      layerProps={{ hostId: 'react-flow' }}
    >
      <h3>Workflow Step Details</h3>
      <TextField
        label="Workflow Step Name"
        value={activeNode?.data.displayName}
        onChange={onStepNameChange}
        readOnly={readOnly}
      />
      <Dropdown
        label="Step Type"
        selectedKey={stepType}
        onChange={onDropdownChange}
        options={dropdownOptions}
        // Prevents dropdown from being rendered after closing the panel when isHiddenOnDismiss.
        hidden={!isPanelOpen}
        disabled={readOnly}
      />
      <h4>Selected {stepType}</h4>
      {activeNode?.data.runnable || activeNode?.data.workflow ? (
        <WidgetItem
          styles={{
            root: {
              display: 'flex',
              alignItems: 'center',
              borderRadius: 8,
              outline: activeNodeSelectedItem ? undefined : '2px dashed red',
            },
            body: {
              color: activeNodeSelectedItem ? undefined : 'red',
            },
          }}
          actions={
            <div>
              {activeNodeSelectedItem && (
                <WidgetIconButton
                  iconName="View"
                  onClick={() => {
                    // TODO: Implement once design is ready.
                  }}
                  title="View"
                />
              )}
              {!readOnly ? (
                <WidgetIconButton
                  iconName="Delete"
                  onClick={removeSelectedItem}
                  iconColor="white"
                  title="Delete"
                  backgroundColor="red"
                  hasBorder={false}
                />
              ) : null}
            </div>
          }
          data={activeNodeSelectedItem || { title: `Selected item does not exist.`, description: '', iconName: '' }}
          idField={'title'}
          titleField={'title'}
        />
      ) : (
        <div
          className={classNames.placeholderContainer}
          style={{
            color: showValidation ? 'red' : undefined,
            outline: showValidation ? '2px dashed red' : undefined,
          }}
        >
          No {stepType} is selected.
        </div>
      )}
      <div style={{ height: 15 }}></div>
      {!readOnly ? (
        <Search
          emptyMessage="No results found"
          hasSearchResult
          // TODO: Handle loading once items are fetched on search.
          // loadingMessage=""
          onClickItem={onItemClick}
          onRenderSearchResultItemActions={() => <></>}
          onSearchTextChange={onSearchChange}
          placeholder={`Search by ${stepType} name`}
          searchResultItems={searchItems}
          minCalloutWidth={452}
          calloutProps={{ dismissOnTargetClick: true }}
          searchResultItemFields={{
            titleField: 'title',
            descriptionField: 'description',
            iconNameField: 'iconName',
          }}
        />
      ) : null}
      <h4>Parameters</h4>
      <KeyValuePairEditor
        config={activeNode?.data.parameters || {}}
        onUpdateConfig={onUpdateConfig}
        styles={{ root: { button: { display: readOnly ? 'none' : undefined } } }}
      />
    </Panel>
  );
};

export default WorkflowCanvasPanel;
