import { IStyle } from '@fluentui/react';
import {
  Button,
  ConfirmDialog,
  IH2OTheme,
  Item,
  TextField,
  itemStylesTag,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { ClassNamesFromIStyles } from '../../utils/models';
import { FailedToLoadView } from './FailedToLoadView';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import WidgetList from './WidgetList';
import { RowHeaderTitle } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

type WorkspaceItem = Workspace & {
  users: string[];
  onClickSetDefault: () => void;
  isActive: boolean;
  activeBackground?: string;
};

interface IWorkspacesStyles {
  wrapper: IStyle;
  content: IStyle;
  title: IStyle;
  description: IStyle;
  buttonContainer: IStyle;
  listWrapper: IStyle;
}

const workspacesStyles = (theme: IH2OTheme): Partial<IWorkspacesStyles> => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.semanticColors?.bodyBackground,
  },
  content: {
    backgroundColor: theme.semanticColors?.contentBackground,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
    borderRadius: 8,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: 800,
    padding: 30,
  },
  title: { marginBottom: 0, marginTop: 20 },
  description: { color: theme.semanticColors?.textSecondary },
  buttonContainer: { position: 'absolute', bottom: -70 },
  listWrapper: {
    maxHeight: 350,
    overflow: 'auto',
  },
});

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName }: WorkspaceItem) => RowHeaderTitle({ title: displayName }),
        iconProps: {
          iconName: 'ProjectCollection',
        },
      },
    },
  },
  {
    key: 'users',
    name: 'Users',
    fieldName: 'users',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    key: 'createdAt',
    name: 'Create Time',
    fieldName: 'createTime',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onClickSetDefault, isActive, activeBackground }: WorkspaceItem) => (
          <>
            {!isActive ? (
              <Button onClick={onClickSetDefault}>Set Default</Button>
            ) : (
              <Item
                data={{
                  style: {
                    backgroundColor: activeBackground,
                    padding: '0px 20px',
                    // lineHeight: 'normal',
                  },
                  title: 'Active',
                }}
                labelField="title"
                styleField="style"
                styles={itemStylesTag}
              />
            )}
          </>
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Workspaces: React.FC = () => {
  const { workspaces, ACTIVE_WORKSPACE_NAME, activateWorkspace, loading, fetchWorkspaces, createWorkspace } =
      useWorkspaces(),
    theme = useTheme(),
    classNames = useClassNames<IWorkspacesStyles, ClassNamesFromIStyles<IWorkspacesStyles>>(
      'workspaces',
      workspacesStyles(theme)
    ),
    [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false),
    [workspaceName, setWorkspaceName] = React.useState(''),
    [showValidation, setShowValidation] = React.useState(false),
    closeDeleteDialog = () => {
      setWorkspaceName('');
      setShowValidation(false);
      setIsCreateDialogOpen(false);
    },
    dialogAction = () => {
      if (!workspaceName) {
        setShowValidation(true);
        return;
      }
      void createWorkspace(workspaceName);
      closeDeleteDialog();
    },
    items: WorkspaceItem[] | undefined = React.useMemo(
      () =>
        workspaces?.map((w) => ({
          ...w,
          // TODO: Show list of users from authz when implementing access control.
          users: ['User'],
          onClickSetDefault: () => activateWorkspace(w.name || ''),
          isActive: w.name === ACTIVE_WORKSPACE_NAME,
          activeBackground: theme.palette?.green100,
        })),
      [workspaces, activateWorkspace, theme, ACTIVE_WORKSPACE_NAME]
    ),
    onAction = () => setIsCreateDialogOpen(true),
    widgetListProps = {
      columns,
      items,
      loading: !!loading,
      delayLoader: false,
      NoItemsContent: NoItemView({
        title: 'Workspaces',
        description: 'There are no workspaces available to select from. Create the first one to begin.',
        actionTitle: 'Create workspace',
        onActionClick: onAction,
        actionIcon: 'Add',
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load workspaces',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchWorkspaces,
        actionIcon: 'Refresh',
      }),
    };

  return (
    <NavigationWrapper>
      <ConfirmDialog
        title="Create workspace"
        hidden={!isCreateDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        confirmationButtonText="Create"
        dismissalButtonText="Cancel"
        modalProps={{ isBlocking: false }}
        content={
          <>
            <TextField
              label="Workspace name"
              value={workspaceName}
              onChange={(_e, newValue) => setWorkspaceName(newValue || '')}
              required
              errorMessage={showValidation && !workspaceName ? 'Please enter a workspace name' : undefined}
            />
          </>
        }
      />
      {!ACTIVE_WORKSPACE_NAME && !loading ? (
        <div className={classNames.wrapper}>
          <div className={classNames.content}>
            {workspaces !== undefined && (
              <>
                <h1 className={classNames.title}>Welcome to Orchestrator</h1>
                <p className={classNames.description}>{`Please ${
                  workspaces?.length ? 'select or ' : ''
                }create a workspace to begin.`}</p>
              </>
            )}
            <div className={classNames.listWrapper}>
              <WidgetList {...widgetListProps} />
            </div>
            {workspaces !== undefined && workspaces.length > 0 && (
              <div className={classNames.buttonContainer}>
                <Button text="Create workspace " onClick={onAction} iconProps={{ iconName: 'Add' }} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <Header customPageTitle="Workspaces" action="Create workspace" actionIcon="Add" onActionClick={onAction} />
          <WidgetList {...widgetListProps} />
        </>
      )}
    </NavigationWrapper>
  );
};

export default Workspaces;
