import * as dagre from '@dagrejs/dagre';
import { MessageBarType } from '@fluentui/react';
import { Pivot, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Edge, Node, ReactFlowProvider, useEdgesState, useNodesState } from 'reactflow';

import { Runnable } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { ListRunnablesResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { WORKFLOW } from './constants';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import {
  ListWorkflowsResponseFixed,
  WorkflowFixed,
  WorkflowStepFixed,
  WorkflowTabCanvas,
  getLabel,
} from './WorkflowTabCanvas';
import WorkflowTabExecutions from './WorkflowTabExecutions';
import WorkflowTabTriggers from './WorkflowTabTriggers';
import { useWorkspaces } from './WorkspaceProvider';

export type WorkflowNavParams = { workflow_id: string; workspace_id: string; tab_id?: string; item_name?: string };

const flexStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fillStyles = {
    pivotContainerStyles: {
      root: {
        '.ms-Pivot-wrapper': flexStyles,
        ...flexStyles,
      },
    },
    styles: {
      itemContainer: {
        '> div': flexStyles,
        ...flexStyles,
      },
    },
  },
  headstartWorkflowStep = {
    uniqueId: 'step-1',
    displayName: 'Step 1',
    runnable: '',
  },
  headstartNodes = [
    {
      id: 'step-1',
      type: 'custom',
      position: { x: 100, y: 100 },
      data: {
        uniqueId: headstartWorkflowStep.uniqueId,
        displayName: headstartWorkflowStep.displayName,
        label: getLabel({ ...headstartWorkflowStep }),
        runnable: headstartWorkflowStep.runnable,
      },
    },
  ],
  graph = new dagre.graphlib.Graph();

export const getInitialNodes = (workflow: WorkflowFixed | undefined) =>
  workflow?.steps
    ? workflow.steps.map(
        (step) =>
          ({
            id: step?.uniqueId || '',
            type: 'custom',
            position: { x: step.xAxis || 0, y: step.yAxis || 0 },
            data: {
              uniqueId: step?.uniqueId || '',
              displayName: step?.displayName || '',
              label: getLabel(step),
              runnable: step?.runnable,
              workflow: step?.workflow,
              parameters: step?.parameters,
              dependsOn: step?.dependsOn,
              isError: false,
              isActive: false,
            },
          } as Node)
      )
    : headstartNodes;
export const getInitialEdges = (workflow: WorkflowFixed | undefined) =>
  (workflow?.steps || []).reduce((acc, step) => {
    if (step.dependsOn) {
      step.dependsOn.forEach((dep) =>
        acc.push({ id: `e${dep}-${step.uniqueId}`, source: dep, target: step.uniqueId || '', animated: true })
      );
    }
    return acc;
  }, [] as Edge[]);
export const getAutoNodes = (graph: dagre.graphlib.Graph<{}>, workflowSteps: WorkflowStepFixed[], nodes: Node[]) => {
  graph.setGraph({});

  graph.setDefaultEdgeLabel(() => ({}));

  workflowSteps.forEach((step) => {
    graph.setNode(step.uniqueId || '', {
      label: step.uniqueId,
      width: WORKFLOW.LABEL_WIDTH + 2 * WORKFLOW.NODE_PADDING_HORIZONTAL,
      height: WORKFLOW.LABEL_HEIGHT + 2 * WORKFLOW.NODE_PADDING_VERTICAL,
    });
  });

  // Add edges to the graph.
  workflowSteps.forEach((step) => {
    if (step.dependsOn) {
      step.dependsOn.forEach((dep) => {
        graph.setEdge(dep, step.uniqueId || '');
      });
    }
  });

  dagre.layout(graph, { rankdir: 'LR' });

  const autoLayoutNodes = graph.nodes().map((v) => graph.node(v));

  return nodes.map((node) => {
    // TODO: Optimize this.
    const layoutNode = autoLayoutNodes.find((n) => n.label === node.id);
    if (layoutNode) {
      return { ...node, position: { x: layoutNode.x, y: layoutNode.y } };
    }
    return node;
  });
};

const WorkflowDetail = () => {
  const location = useLocation(),
    history = useHistory(),
    [workflow, setWorkflow] = React.useState<WorkflowFixed | undefined>(
      (location.state as any)?.state as WorkflowFixed
    ),
    [selectedKey, setSelectedKey] = React.useState<'0' | '1' | '2'>('0'),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    params = useParams<WorkflowNavParams>(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    nodeState = useNodesState(getInitialNodes(workflow)),
    edgeState = useEdgesState(getInitialEdges(workflow)),
    [nodes, setNodes] = nodeState,
    [edges, setEdges] = edgeState,
    [isSaved, setIsSaved] = React.useState(true),
    [workflowName, setWorkflowName] = React.useState<string>(workflow?.displayName || ''),
    [showValidation, setShowValidation] = React.useState(false),
    [concurrencyLimit, setConcurrencyLimit] = React.useState<number>(workflow?.concurrencyLimit || 0),
    [timeout, setTimeout] = React.useState<string | null>(workflow?.timeout?.slice(0, -1) || null),
    [loading, setLoading] = React.useState(false),
    [workflows, setWorkflows] = React.useState<WorkflowFixed[]>(),
    [runnables, setRunnables] = React.useState<Runnable[]>(),
    getWorkflowBody = React.useCallback(() => {
      const nameUniformEdges = edges.map((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source),
          targetNode = nodes.find((node) => node.id === edge.target),
          source = sourceNode ? `step-${nodes.indexOf(sourceNode)}` : edge.source,
          target = targetNode ? `step-${nodes.indexOf(targetNode)}` : edge.target;
        return { ...edge, source, target };
      });
      return {
        parent: ACTIVE_WORKSPACE_NAME || '',
        workflow: {
          name: workflow?.name,
          displayName: workflowName,
          steps: nodes.map((node, idx) => {
            const dependsOn = nameUniformEdges
              .filter((edge) => edge.target === `step-${idx}`)
              .map((edge) => edge.source);
            return {
              uniqueId: `step-${idx}`,
              displayName: node.data.displayName,
              dependsOn,
              runnable: node.data.runnable,
              workflow: node.data.workflow,
              parameters: node.data.parameters,
              xAxis: node.position.x,
              yAxis: node.position.y,
            };
          }),
          concurrencyLimit,
          timeout: timeout ? `${timeout}s` : null,
        },
      };
    }, [nodes, edges, workflowName, workflow?.name, ACTIVE_WORKSPACE_NAME, timeout, concurrencyLimit]),
    createWorkflow = React.useCallback(async () => {
      try {
        await orchestratorService.createWorkflow({ ...getWorkflowBody() });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Workflow created successfully.',
        });
        history.goBack();
      } catch (err) {
        const message = `Failed to create workflow: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    }, [getWorkflowBody, history, orchestratorService]),
    updateWorkflow = React.useCallback(async () => {
      try {
        await orchestratorService.editWorkflow({
          workflow: { ...getWorkflowBody().workflow },
          updateMask: 'displayName,steps',
        });
        setIsSaved(true);
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Workflow updated successfully.',
        });
      } catch (err) {
        const message = `Failed to update workflow: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    }, [getWorkflowBody, orchestratorService]),
    onActionClick = () => {
      // For each step checks if the runnable/workflow is both specified and exists within the workspace.
      const allWorkflowStepsValid = nodes.every(
        (node) => searchItemsNameMappings[node.data.runnable || node.data.workflow]
      );
      if (!workflowName || !allWorkflowStepsValid) {
        setShowValidation(true);
        return;
      }
      if (!workflow) void createWorkflow();
      else void updateWorkflow();
    },
    [searchItemsNameMappings, setSearchItemsNameMappings] = React.useState<{ [key: string]: string }>({}),
    canvasTabProps = {
      nodeState,
      edgeState,
      workflowName,
      onWorkflowNameChange: setWorkflowName,
      defaultWorkflow: workflow,
      showValidation,
      timeout,
      concurrencyLimit,
      onConcurrencyLimitChange: setConcurrencyLimit,
      onTimeoutChange: setTimeout,
      workflows,
      runnables,
      searchItemsNameMappings,
      isSaved,
      setIsSaved,
      loading,
    },
    executionsTabProps = {
      searchItemsNameMappings,
      workflow,
    },
    fetchRunnables = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ListRunnablesResponse = await orchestratorService.getRunnables({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setRunnables(data?.runnables);
      } catch (err) {
        const message = `Failed to fetch runnables: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setRunnables(undefined);
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    fetchWorkflows = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ListWorkflowsResponseFixed = await orchestratorService.getWorkflows({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setWorkflows(data?.workflows);
        if (!location.state) {
          const workflow = data?.workflows?.find(
            (w) => w.name === `workspaces/${params.workspace_id}/workflows/${params.workflow_id}`
          );
          setWorkflow(workflow);
          if (!workflowName) {
            setWorkflowName(workflow?.displayName || `New Workflow ${(data?.workflows || []).length + 1}`);
          }
        }
      } catch (err) {
        const message = `Failed to fetch workflows: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflows(undefined);
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService, params.workspace_id, params.workflow_id, workflowName]);

  React.useEffect(() => {
    if (workflow) {
      setNodes(getInitialNodes(workflow));
      setEdges(getInitialEdges(workflow));
      setWorkflowName(workflow?.displayName || '');
      setConcurrencyLimit(workflow?.concurrencyLimit || 0);
      setTimeout(workflow?.timeout?.slice(0, -1) || null);
    }
  }, [workflow]);

  React.useEffect(() => {
    if (!params.tab_id && selectedKey !== '0') setSelectedKey('0');
    if (params.tab_id === 'triggers' && selectedKey !== '1') setSelectedKey('1');
    if (params.tab_id === 'executions' && selectedKey !== '2') setSelectedKey('2');
  }, [params.tab_id]);

  React.useEffect(() => {
    const mappings = [...(runnables || []), ...(workflows || [])].reduce((acc, { name, displayName }) => {
      if (name && displayName) acc[name] = displayName;
      return acc;
    }, {} as { [key: string]: string });
    setSearchItemsNameMappings(mappings);
  }, [runnables, workflows]);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchWorkflows();
      void fetchRunnables();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    // Calculate initial positions for nodes if it was created through API (xAxis and yAxis of all nodes are 0).
    const noNodeIsPositioned = nodes.every((node) => node.position.x === 0 && node.position.y === 0);
    if (noNodeIsPositioned) {
      setNodes((nodes) => {
        return getAutoNodes(graph, workflow?.steps || [], nodes);
      });
    }
  }, []);

  return (
    <NavigationWrapper>
      <Header
        customPageTitle="Workflow Details"
        action={
          selectedKey === '0' && permissions.canEditWorkflows
            ? workflow
              ? 'Save changes'
              : 'Create workflow'
            : undefined
        }
        onActionClick={onActionClick}
      />
      <Pivot
        {...fillStyles}
        onLinkClick={(item) => {
          if (!item) return;
          const tabId = item.props.itemKey === '1' ? 'triggers' : item.props.itemKey === '2' ? 'executions' : '';
          // TODO: Ask about unsaved changes.
          history.push(`/orchestrator/workspaces/${params.workspace_id}/workflows/${params.workflow_id}/${tabId}`, {
            state: location.state,
          });
        }}
        selectedKey={selectedKey}
        items={[
          {
            content: (
              <ReactFlowProvider>
                <WorkflowTabCanvas {...canvasTabProps} />
              </ReactFlowProvider>
            ),
            headerText: 'Details',
          },
          {
            content: <WorkflowTabTriggers />,
            headerText: 'Triggers',
          },
          {
            content: <WorkflowTabExecutions {...executionsTabProps} />,
            headerText: 'Executions',
          },
        ]}
        placeholder={undefined}
      />
    </NavigationWrapper>
  );
};

export default WorkflowDetail;
