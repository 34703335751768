import { Shimmer } from '@fluentui/react';

export function MetadataTable(props: { children: React.ReactNode }) {
  return <table style={{ margin: '-12px 0 20px 4px' }}>{props.children}</table>;
}

export function MetadataTableBody(props: { children: React.ReactNode }) {
  return <tbody>{props.children}</tbody>;
}

export function MetadataRow(props: { children: React.ReactNode }) {
  return <tr>{props.children}</tr>;
}

export function MetadataCell(props: { children: React.ReactNode; colspan?: number }) {
  return <td colSpan={props.colspan}>{props.children}</td>;
}

export function MetadataLabelCell(props: { children: React.ReactNode; colspan?: number }) {
  return (
    <td colSpan={props.colspan} style={{ fontWeight: 600 }}>
      {props.children}
    </td>
  );
}

export function MetadataValueCell(props: { children: React.ReactNode; loading?: boolean; colspan?: number }) {
  return <td colSpan={props.colspan}>{props.loading ? <Shimmer width={60} /> : props.children}</td>;
}
