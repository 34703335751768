import { IStyle } from '@fluentui/react';
import { Button, PageHeader, buttonStylesPrimary, useClassNames } from '@h2oai/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';

import { ClassNamesFromIStyles } from '../../utils/models';

type BreadcrumbsProps = {
  action?: string;
  onActionClick?: () => void;
  customPageTitle?: string;
  actionIcon?: string;
};

interface IBreadcrumbsStyles {
  root: IStyle;
  actionButtonContainer: IStyle;
  actionButton: IStyle;
}

const breadcrumbsStyles: Partial<IBreadcrumbsStyles> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    alignItems: 'end',
  },
  actionButton: {
    marginRight: 10,
  },
};

const headerStyles = {
  root: {
    padding: '10px 20px',
    minWidth: 500,
  },
  title: {
    textTransform: 'capitalize',
  },
};

const Header = ({ action, onActionClick, actionIcon, customPageTitle }: BreadcrumbsProps) => {
  const history = useHistory(),
    location = useLocation(),
    classNames = useClassNames<IBreadcrumbsStyles, ClassNamesFromIStyles<IBreadcrumbsStyles>>(
      'breadcrumbs',
      breadcrumbsStyles
    ),
    path = location.pathname.split('/'),
    pageTitle = path[path.length - 1],
    items = ['home', ...path]
      .filter((it) => it !== '')
      .map((item, idx) => {
        return {
          key: item,
          text: item,
          onClick: () => history.push(path.slice(0, idx + 1).join('/') || '/'),
        };
      });

  return (
    <div className={classNames.root}>
      <PageHeader
        breadcrumbProps={
          location.pathname !== '/'
            ? {
                items,
                styles: {
                  itemLink: {
                    textTransform: 'capitalize',
                  },
                },
              }
            : undefined
        }
        styles={headerStyles}
        pageTitle={customPageTitle || pageTitle}
      />
      {action ? (
        <div className={classNames.actionButtonContainer}>
          <Button
            className={classNames.actionButton}
            styles={buttonStylesPrimary}
            iconProps={actionIcon ? { iconName: actionIcon } : undefined}
            text={action}
            onClick={onActionClick}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default Header;
