import { Image, Stack, Text } from '@fluentui/react';
import { Button, FontSizes, FontWeights, buttonStylesPrimary, useHaicPageTitle, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { ErrorCode, getHTTPResponse } from '../services/HTTPResponse';
import { useEnv } from '../utils/hooks';

export interface ErrorPageProps {
  code?: ErrorCode;
  message?: string;
  pageTitle?: string;
}

function ErrorPage({ pageTitle = 'An Error Occurred', code, message }: ErrorPageProps) {
  if (!code) {
    code = ErrorCode.Unknown;
    message = 'invalid format';
  }
  const theme = useTheme(),
    httpResponse = getHTTPResponse({ code, message }),
    { status } = httpResponse,
    image = [404, 401].includes(httpResponse.status!) ? httpResponse.status!.toString() : '500',
    env = useEnv();

  useHaicPageTitle(pageTitle, env?.cloudInstanceName);
  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingTop: 150 } }}>
      <Stack>
        <Stack
          styles={{
            root: {
              padding: 4,
              marginBottom: 8,
              backgroundColor: theme.semanticColors?.buttonPrimaryBackground,
              width: 'max-content',
              height: 37,
            },
          }}
        >
          <Text
            styles={{ root: { fontSize: FontSizes.xlarge, fontWeight: FontWeights.bold } }}
          >{`${status} Error`}</Text>
        </Stack>
        <Text styles={{ root: { fontSize: FontSizes.xxxlarge, fontWeight: FontWeights.normal } }}>
          {message || httpResponse?.message}
        </Text>
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingTop: 41, paddingBottom: 40 } }}>
          <Image src={`/${image}.svg`}></Image>
        </Stack>
        <Stack horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 16 }}>
          <Button
            styles={buttonStylesPrimary}
            text="Return home"
            href="/"
            data-test={`${code}-error-page-return-home-button`}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export const Error401Page = () => (
  <ErrorPage
    pageTitle="401 Error"
    code={ErrorCode.Unauthenticated}
    message="It seems like you need permission to view this page"
  />
);
export const Error404Page = () => (
  <ErrorPage
    pageTitle="404 Error"
    code={ErrorCode.NotFound}
    message="Sorry, we can't seem to find what you are looking for"
  />
);
export const Error500Page = () => (
  <ErrorPage
    pageTitle="500 Error"
    code={ErrorCode.Internal}
    message="Internal server error. Please refresh this page or try again later"
  />
);
export const NotFoundPage = () => {
  const [errorMessage, setErrorMessage] = useState('Page not found');
  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    const notFoundInstance = queryParams?.instance_id;
    if (notFoundInstance) console.warn(`Instance ${notFoundInstance} not found`);

    const notFoundAlias = queryParams?.alias;
    if (notFoundAlias) console.warn(`Alias ${notFoundAlias} not found`);

    if (notFoundAlias || notFoundInstance) setErrorMessage('App instance not found');
  }, [window.location.search]);

  return (
    <ErrorPage
      pageTitle={errorMessage}
      code={ErrorCode.NotFound}
      message={`${errorMessage}. Please refresh this page or try again later`}
    />
  );
};

export default ErrorPage;
