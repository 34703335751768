// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/runnable_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { Runnable } from "./runnable_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for CreateRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateRunnableRequest
 */
export type CreateRunnableRequest = {
/**
 * Required. The resource name of the workspace to create Runnable in.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The ID to use for the runnable, which will become the final component of the runnable resource name.
 *
 * @generated from field: string runnable_id = 2;
 */
runnableId?: string;
/**
 * Required. The Runnable resource to create.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable runnable = 3;
 */
runnable: Runnable;
}
;
/**
 * Response message for CreateRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateRunnableResponse
 */
export type CreateRunnableResponse = {
/**
 * The created Runnable.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable runnable = 1;
 */
runnable?: Runnable;
}
;
/**
 * Request message for UpdateRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateRunnableRequest
 */
export type UpdateRunnableRequest = {
/**
 * Required. Runnable resource to update
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable runnable = 1;
 */
runnable: Runnable;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateRunnableResponse
 */
export type UpdateRunnableResponse = {
/**
 * Updated Runnable resource.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable runnable = 1;
 */
runnable?: Runnable;
}
;
/**
 * Request message for GetRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.GetRunnableRequest
 */
export type GetRunnableRequest = {
/**
 * Required. Runnable resource name.
 * Format: workspaces/{workspace}/runnables/{runnable}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.GetRunnableResponse
 */
export type GetRunnableResponse = {
/**
 * The requested runnable.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable runnable = 1;
 */
runnable?: Runnable;
}
;
/**
 * Request message ListRunnables
 *
 * @generated from message ai.h2o.orchestrator.v1.ListRunnablesRequest
 */
export type ListRunnablesRequest = {
/**
 * Required. The resource name of the workspace to list runnables from.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListRunnables
 *
 * @generated from message ai.h2o.orchestrator.v1.ListRunnablesResponse
 */
export type ListRunnablesResponse = {
/**
 * Collection of runnables.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.Runnable runnables = 1;
 */
runnables?: Runnable[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for DeleteRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteRunnableRequest
 */
export type DeleteRunnableRequest = {
/**
 * Required. Runnable resource name.
 * Format: workspaces/{workspace}/runnables/{runnable}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteRunnable
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteRunnableResponse
 */
export type DeleteRunnableResponse = {
}
;
/**
 * Creates a new Runnable within a specified workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.RunnableService.CreateRunnable
 */
export const RunnableService_CreateRunnable = new RPC<CreateRunnableRequest, CreateRunnableResponse>("POST", "/v1/{parent=workspaces/*}/runnables", "runnable");
/**
 * Updates a Runnable.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.RunnableService.UpdateRunnable
 */
export const RunnableService_UpdateRunnable = new RPC<UpdateRunnableRequest, UpdateRunnableResponse>("PATCH", "/v1/{runnable.name=workspaces/*/runnables/*}", "runnable");
/**
 * Deletes a Runnable.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.RunnableService.DeleteRunnable
 */
export const RunnableService_DeleteRunnable = new RPC<DeleteRunnableRequest, DeleteRunnableResponse>("DELETE", "/v1/{name=workspaces/*/runnables/*}");
/**
 * Returns a specific Runnable.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.RunnableService.GetRunnable
 */
export const RunnableService_GetRunnable = new RPC<GetRunnableRequest, GetRunnableResponse>("GET", "/v1/{name=workspaces/*/runnables/*}");
/**
 * Returns a collection of Runnables within a workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.RunnableService.ListRunnables
 */
export const RunnableService_ListRunnables = new RPC<ListRunnablesRequest, ListRunnablesResponse>("GET", "/v1/{parent=workspaces/*}/runnables");
