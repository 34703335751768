import { ITextStyles, Stack, Text } from '@fluentui/react';
import { FontSizes, LineHeights, Sizes, getDate, useTheme } from '@h2oai/ui-kit';

import { App } from '../../../../ai.h2o.cloud.appstore';
import { appVisibilityMap } from '../../../../utils/utils';

type AdditionalInfoProps = {
  app: App;
};

const tokens = { childrenGap: 10 };

export function AdditionalInfo({ app }: AdditionalInfoProps) {
  const theme = useTheme(),
    { owner, visibility } = app,
    createTime = getDate(app.createTime),
    updateTime = getDate(app.updateTime),
    containerStyles: ITextStyles = {
      root: {
        lineHeight: LineHeights.xxxlarge,
        borderRadius: Sizes.borderRadius,
        backgroundColor: theme.semanticColors?.bodyBackground,
      },
    },
    labelStyles: ITextStyles = {
      root: {
        color: theme.semanticColors?.textPrimary,
        fontSize: FontSizes.textPrimary,
        width: 155,
        paddingRight: 10,
      },
    },
    contentStyles: ITextStyles = {
      root: {
        fontSize: FontSizes.textPrimary,
        color: theme.semanticColors?.textPrimary,
        wordBreak: 'break-word',
      },
    };

  return (
    <Stack
      className="AdditionalInfo"
      horizontalAlign="start"
      tokens={tokens}
      styles={{
        root: {
          background: theme.palette?.gray200,
          borderRadius: Sizes.borderRadius,
          padding: '17px 24px',
          flexGrow: 0,
        },
      }}
    >
      <Stack horizontalAlign="space-between">
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Owner
          </Text>
          <Text variant="small" styles={contentStyles}>
            {owner}
          </Text>
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Created At
          </Text>
          {createTime ? (
            <>
              <Text variant="small" styles={contentStyles}>
                {`${createTime.toDateString()} | ${createTime.toLocaleTimeString()}`}
              </Text>
            </>
          ) : (
            <Text variant="small" styles={contentStyles}>
              {app.createTime}
            </Text>
          )}
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Last Updated At
          </Text>
          {updateTime ? (
            <>
              <Text variant="small" styles={contentStyles}>
                {`${updateTime.toDateString()} | ${updateTime.toLocaleTimeString()}`}
              </Text>
            </>
          ) : (
            <Text variant="small" styles={contentStyles}>
              {app.updateTime}
            </Text>
          )}
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Visibility
          </Text>
          <Text variant="small" styles={contentStyles}>
            {appVisibilityMap[visibility]}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
