import {
  CollapseAllVisibility,
  IDetailsGroupRenderProps,
  IDetailsRowProps,
  IGroup,
  IRenderFunction,
  IStyle,
  Icon,
  MessageBarType,
  SpinnerSize,
  Toggle,
  getColorFromString,
  isDark,
  keyframes,
} from '@fluentui/react';
import {
  Button,
  CodeBlock,
  DetailsList,
  IH2OTheme,
  IconButton,
  Item,
  Link,
  ListRow,
  Loader,
  buttonStylesIcon,
  itemStylesTag,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  WorkflowExecution,
  WorkflowExecution_State,
  WorkflowExecution_WorkflowStepExecution,
  WorkflowExecution_WorkflowStepExecution_RelatedWorkflowExecution,
  WorkflowExecution_WorkflowStepExecution_RunnableExecution,
  WorkflowExecution_WorkflowStepExecution_State,
} from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_execution_pb';
import { ListWorkflowExecutionsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_execution_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { FailedToLoadView } from './FailedToLoadView';
import { NoItemView } from './NoItemView';
import { useRoles } from './RoleProvider';
import WidgetList from './WidgetList';
import { WorkflowNavParams } from './WorkflowDetail';
import WorkflowExecutionFlowView from './WorkflowExecutionFlowView';
import { ContextMenuIconButton } from './Workflows';
import { OneOf, WorkflowFixed } from './WorkflowTabCanvas';

interface IWorkflowTabExecutionsProps {
  searchItemsNameMappings: { [key: string]: string };
  workflow?: WorkflowFixed;
}

// TODO: Replace these types once generated ts api is fixed.
export type WorkflowStepExecutionFixed = WorkflowExecution_WorkflowStepExecution &
  OneOf<{
    runnableExecution?: WorkflowExecution_WorkflowStepExecution_RunnableExecution;
    workflowExecution?: WorkflowExecution_WorkflowStepExecution_RelatedWorkflowExecution;
  }>;
export type WorkflowExecutionFixed = Omit<WorkflowExecution, 'stepExecutions'> & {
  stepExecutions?: WorkflowStepExecutionFixed[];
};
export type ListWorkflowExecutionsResponseFixed = Omit<ListWorkflowExecutionsResponse, 'workflowExecutions'> & {
  workflowExecutions?: WorkflowExecutionFixed[];
};

export type WorkflowExecutionItem = WorkflowExecutionFixed & {
  duration: string;
  workflowDisplayName?: string;
  runBy: string;
  viewOnly?: boolean;
  onView: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
};

export const RowHeaderTitle = ({ title, onClick }: { title?: string; onClick?: () => void }) =>
  onClick ? (
    <Link
      onClick={onClick}
      styles={{
        root: {
          color: '#323130', // TODO: Find out how to theme this.
          textDecoration: 'none',
          '&:hover': { color: '#323130' },
        },
      }}
    >
      <h3 style={{ margin: 0 }}>{title}</h3>
    </Link>
  ) : (
    <h3 style={{ margin: 0 }}>{title}</h3>
  );

export const executionColumns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'runBy',
    minWidth: 180,
    maxWidth: 360,
    data: {
      headerFieldName: 'workflowDisplayName',
      listCellProps: {
        onRenderHeader: ({ workflowDisplayName, onView }: WorkflowExecutionItem) =>
          RowHeaderTitle({ title: workflowDisplayName, onClick: onView }),
        iconProps: {
          iconName: 'ChevronRightSmall',
        },
      },
    },
  },
  {
    key: 'status',
    name: '',
    fieldName: 'status',
    minWidth: 90,
    maxWidth: 180,
    data: {
      listCellProps: {
        styles: {
          root: {
            justifyContent: 'center',
          },
        },
        onRenderText: ({ state }: WorkflowExecutionItem) => {
          return state === WorkflowExecution_State.RUNNING ? (
            <Loader label="Running" />
          ) : (
            <Tag title={getStateProps(state).name} color={getStateProps(state).color} />
          );
        },
      },
    },
  },
  {
    key: 'duration',
    name: 'Duration',
    fieldName: 'duration',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'startTime',
    name: 'Start Time',
    fieldName: 'startTime',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'endTime',
    name: 'End Time',
    fieldName: 'endTime',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 140,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onView, onCancel, onDelete, state, viewOnly }: WorkflowExecutionItem) => (
          <ContextMenuIconButton
            items={[
              {
                key: 'view',
                text: 'View execution',
                onClick: onView,
                // TODO: Use theme prop for colors.
                iconProps: { iconName: 'View', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'cancel',
                text: 'Cancel execution',
                onClick: onCancel,
                iconProps: { iconName: 'Cancel', style: { color: 'var(--h2o-yellow700)' } },
                style: {
                  color: 'var(--h2o-yellow700)',
                  display: state === WorkflowExecution_State.RUNNING && !viewOnly ? undefined : 'none',
                },
              },
              {
                key: 'delete',
                text: 'Delete execution',
                onClick: onDelete,
                style: {
                  color: 'var(--h2o-red400)',
                  display: state === WorkflowExecution_State.RUNNING || viewOnly ? 'none' : undefined,
                },
                iconProps: {
                  iconName: 'Delete',
                  style: { color: 'var(--h2o-red400)' },
                },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

export type StateProps = {
  name: string;
  icon: string;
  color: string;
};

// TODO: Find out how to theme this.
export const getStateProps = (state?: WorkflowExecution_WorkflowStepExecution_State | WorkflowExecution_State) => {
    switch (state) {
      case WorkflowExecution_WorkflowStepExecution_State.CANCELLED:
        return { name: 'Cancelled', icon: 'Cancel', color: 'var(--h2o-red500)', message: 'This step was cancelled.' }; // "semanticColors.messageBarIconError"
      case WorkflowExecution_WorkflowStepExecution_State.FAILED:
        return { name: 'Failed', icon: 'ErrorBadge', color: 'var(--h2o-red400)', message: 'This step has failed.' }; // "palette.red400"
      case WorkflowExecution_WorkflowStepExecution_State.FINISHED:
        return {
          name: 'Finished',
          icon: 'Completed',
          color: 'var(--h2o-green200)',
          message: 'This step finished successfuly.',
        }; // "palette.green200"
      case WorkflowExecution_WorkflowStepExecution_State.RUNNING:
        return {
          name: 'Running',
          icon: 'Running',
          color: 'var(--h2o-blue500)',
          message: 'This step is currently running.',
        }; // "semanticColors.messageBarIconInfo"
      case WorkflowExecution_WorkflowStepExecution_State.UNSPECIFIED:
        return {
          name: 'Unspecified',
          icon: 'Unknown',
          color: 'var(--h2o-gray900)',
          message: 'The state of the step is not specified.',
        }; // "semanticColors.textPrimary"
      case WorkflowExecution_WorkflowStepExecution_State.PENDING:
        return {
          name: 'Pending',
          icon: 'Clock',
          color: 'var(--h2o-yellow500)',
          message: 'The execution of this step is pending.',
        }; // "semanticColors.messageBarIconWarning"
      case WorkflowExecution_WorkflowStepExecution_State.BLOCKING:
        return {
          name: 'Blocking',
          icon: 'Blocked',
          color: 'var(--h2o-yellow900)',
          message: 'The execution of this step is blocked.',
        }; // "semanticColors.messageBarContentTextWarning"
      case WorkflowExecution_WorkflowStepExecution_State.UPSTREAM_FAILED:
        return { name: 'Upstream Failed', icon: 'Error', color: 'var(--h2o-red500)', message: 'This step has failed.' }; // "semanticColors.messageBarIconError"
      default:
        return {
          name: 'Unknown',
          icon: 'Unknown',
          color: 'var(--h2o-gray900)',
          message: 'There is no output for this step.',
        }; // "semanticColors.textPrimary"
    }
  },
  cssVarValue = (cssVar: string) => {
    return getComputedStyle(document.body).getPropertyValue(cssVar).trim();
  },
  Tag = ({ title, color }: { title: string; color: string }) => (
    <Item
      data={{ title }}
      labelField="title"
      styles={[
        itemStylesTag,
        {
          root: {
            backgroundColor: color,
            width: 80,
            lineHeight: 21,
            justifyContent: 'center',
          },
          labelText: {
            color: isDark(
              getColorFromString(
                color.startsWith('var') ? cssVarValue(color.substring(4).slice(0, -1)) : color || '#fff'
              )!
            )
              ? 'var(--h2o-gray200)'
              : 'var(--h2o-gray900)',
          },
        },
      ]}
    />
  ),
  getListRowStyles = (theme: IH2OTheme) => ({
    details: {
      padding: 20,
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    root: {
      marginTop: 20,
      borderRadius: 4,
      backgroundColor: theme.semanticColors?.contentBackground,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    row: {
      '&:hover': {
        background: 'transparent',
      },
      '.h2o-ListCell-root:first-child': {
        paddingLeft: 20,
      },
      height: 80,
    },
  }),
  listRowColumns = [
    {
      data: {
        headerFieldName: 'workflowDisplayName',
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderHeader: ({ workflowDisplayName }: WorkflowExecutionItem) => (
            <h3 style={{ padding: 0, margin: 0 }}>{`Execution of ${workflowDisplayName}`}</h3>
          ),
        },
      },
      fieldName: 'runBy',
      key: 'title',
      maxWidth: 700,
      minWidth: 400,
      name: 'Title',
    },
    {
      fieldName: 'state',
      key: 'state',
      maxWidth: 200,
      minWidth: 140,
      name: 'State',
      data: {
        listCellProps: {
          onRenderText: ({ state }: WorkflowExecutionItem) => {
            return state === WorkflowExecution_State.RUNNING ? (
              <Loader
                label="Running"
                styles={[loaderStylesSpinnerDefault, loaderStylesSpinnerTag, { root: { position: 'relative' } }]}
              />
            ) : (
              <Tag title={getStateProps(state).name} color={getStateProps(state).color} />
            );
          },
        },
      },
    },
    {
      fieldName: 'startTime',
      key: 'startTime',
      maxWidth: 200,
      minWidth: 130,
      name: 'Started At',
    },
    {
      fieldName: 'endTime',
      key: 'endTime',
      maxWidth: 200,
      minWidth: 130,
      name: 'Ended At',
    },
    {
      fieldName: 'duration',
      key: 'duration',
      maxWidth: 240,
      minWidth: 180,
      name: 'Duration',
    },
    {
      key: 'buttons',
      name: '',
      maxWidth: 260,
      minWidth: 180,
      data: {
        listCellProps: {
          onRenderText: ({ onCancel, onDelete, state, viewOnly }: WorkflowExecutionItem) => (
            <>
              {state === WorkflowExecution_State.RUNNING && !viewOnly ? (
                <Button
                  text="Cancel"
                  onClick={onCancel}
                  style={{ color: 'var(--h2o-yellow700)', borderColor: 'var(--h2o-yellow700)' }}
                  iconProps={{ iconName: 'Cancel', style: { color: 'var(--h2o-yellow700)' } }}
                />
              ) : null}
              {state !== WorkflowExecution_State.RUNNING && !viewOnly ? (
                <Button
                  text="Delete"
                  onClick={onDelete}
                  style={{
                    color: 'var(--h2o-red400)',
                    borderColor: 'var(--h2o-red400)',
                  }}
                  iconProps={{ iconName: 'Delete', style: { color: 'var(--h2o-red400)' } }}
                />
              ) : null}
            </>
          ),
        },
      },
    },
  ],
  revisionToResourceName = (revisionName?: string) => revisionName?.split('/', 4)?.join('/') || '';

export const calculateDuration = (startTime?: string, endTime: string = new Date().toISOString()) => {
  if (!startTime) return 'N/A';
  const start = new Date(startTime),
    end = new Date(endTime),
    duration = end.getTime() - start.getTime(),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24)),
    dayStr = days > 0 ? `${days}d ` : '',
    hourStr = hours > 0 ? `${hours}h ` : '',
    minuteStr = minutes > 0 ? `${minutes}m ` : '',
    secondStr = seconds > 0 ? `${seconds}s` : '0s';

  return `${dayStr}${hourStr}${minuteStr}${secondStr}`;
};

interface IWorkflowExecutionsTabStyles {
  contentWrapper: IStyle;
  detailsListWrapper: IStyle;
  executionDetailContainer: IStyle;
  executionDetailHeader: IStyle;
  executionStepOutputContainer: IStyle;
  groupContainer: IStyle;
  groupLabel: IStyle;
  groupLabelTitle: IStyle;
  groupLabelStatus: IStyle;
  goToDetailButton: IStyle;
  noOutputMessage: IStyle;
}

const workflowExecutionsTabStyles = (theme: IH2OTheme): IWorkflowExecutionsTabStyles => {
    return {
      contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '100%',
      },
      detailsListWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto',
        maxHeight: '100%',
        height: '100%',
        backgroundColor: theme.semanticColors?.contentBackground,
        borderRadius: 4,
        outlineWidth: 1,
        outlineStyle: 'solid',
        outlineColor: theme.semanticColors?.inputBorder,
        scrollBehavior: 'smooth',
      },
      executionDetailContainer: { margin: 20, display: 'flex', flexGrow: 1, flexDirection: 'column' },
      executionDetailHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      executionStepOutputContainer: { marginLeft: 30 },
      groupContainer: {
        display: 'flex',
        padding: '15px 25px',
        alignItems: 'flex-start',
        ':target': {
          animation: 'highlight 1.2s ease-out 0.15s',
          animationName: keyframes({ from: { background: theme.palette?.yellow300 } }),
        },
      },
      groupLabel: { margin: 0, marginLeft: 4 },
      groupLabelTitle: { margin: 0 },
      groupLabelStatus: { margin: 0, marginTop: 4 },
      goToDetailButton: { margin: '6px 0px' },
      noOutputMessage: { marginLeft: 26, marginTop: 6 },
    };
  },
  getDetailGroups = (executionDetail?: WorkflowExecutionItem, searchItemsNameMappings?: { [key: string]: string }) => {
    return (
      (executionDetail?.stepExecutions?.map((stepExecution, idx) => ({
        key: stepExecution.uniqueId || '',
        name: stepExecution.runnableExecution?.runnable
          ? searchItemsNameMappings?.[revisionToResourceName(stepExecution.runnableExecution.runnable)] ||
            revisionToResourceName(stepExecution.runnableExecution.runnable)
          : stepExecution.workflowExecution?.workflowExecution
          ? searchItemsNameMappings?.[revisionToResourceName(stepExecution.workflowExecution.workflowExecution)] ||
            revisionToResourceName(stepExecution.workflowExecution.workflowExecution)
          : '(Unknown)',
        startIndex: idx,
        count: 1,
        status: getStateProps(stepExecution.state).name,
        stateIconName: getStateProps(stepExecution.state).icon,
        stateColor: getStateProps(stepExecution.state).color,
        duration: calculateDuration(stepExecution.startTime, stepExecution.endTime),
        isCollapsed: true,
      })) as IGroup[]) || ([] as IGroup[])
    );
  };

const WorkflowTabExecutions = ({ searchItemsNameMappings, workflow }: IWorkflowTabExecutionsProps) => {
  const orchestratorService = useOrchestratorService(),
    theme = useTheme(),
    history = useHistory(),
    location = useLocation(),
    params = useParams<WorkflowNavParams>(),
    { addToast } = useToast(),
    { permissions } = useRoles(),
    classNames = useClassNames<IWorkflowExecutionsTabStyles, ClassNamesFromIStyles<IWorkflowExecutionsTabStyles>>(
      'workflowExecutionsTab',
      workflowExecutionsTabStyles(theme)
    ),
    [loading, setLoading] = React.useState<boolean>(false),
    [executionsItems, setExecutionsItems] = React.useState<WorkflowExecutionItem[]>(),
    [executionDetail, setExecutionDetail] = React.useState<WorkflowExecutionItem>(),
    [showVisualDetail, setShowVisualDetail] = React.useState<boolean>(true),
    [detailGroups, setDetailGroups] = React.useState<IGroup[] | undefined>(),
    deleteExecution = async (executionName: string) => {
      setLoading(true);
      try {
        await orchestratorService.deleteWorkflowExecution({ name: executionName });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Execution deleted successfully',
        });
        void fetchExecutions();
      } catch (err) {
        const message = `Failed to delete execution: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    cancelExecution = async (executionName: string) => {
      setLoading(true);
      try {
        await orchestratorService.cancelWorkflowExecution({ name: executionName });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Execution cancelled successfully',
        });
        void fetchExecutions();
      } catch (err) {
        const message = `Failed to cancel execution: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    fetchExecutions = React.useCallback(async () => {
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflowExecutions({
          parent: workflow?.name || '',
        });
        const items = data?.workflowExecutions
          ? data.workflowExecutions.map((item) => ({
              ...item,
              workflowDisplayName: workflow?.displayName || '',
              // TODO: Add user/workflow name once the API provides initiatorDisplayName prop.
              runBy: item.initiator
                ? `${item.initiator.includes('/workflows') ? 'Triggered' : 'Manually run'} by ${item.initiator}`
                : '(Unknown)',
              duration: calculateDuration(item.startTime, item.endTime),
              onView: () => history.push(`/orchestrator/${item.name}`),
              onCancel: () => cancelExecution(item.name || ''),
              onDelete: () => deleteExecution(item.name || ''),
              viewOnly: !permissions.canRunWorkflows,
            }))
          : undefined;
        setExecutionsItems(items);
      } catch (err) {
        const message = `Failed to fetch workflow executions: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setExecutionsItems(undefined);
      } finally {
        setLoading(false);
      }
    }, [workflow?.name, workflow?.displayName, permissions]),
    groupProps: IDetailsGroupRenderProps | undefined = {
      collapseAllVisibility: CollapseAllVisibility.hidden,
      onRenderHeader: (props) => {
        const group = props!.group as IGroup & {
          status: string;
          stateIconName: string;
          stateColor: string;
          duration: string;
        };
        return (
          <div className={classNames.groupContainer} id={group.key}>
            <IconButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: group.isCollapsed ? 'ChevronRight' : 'ChevronDown' }}
              onClick={() => {
                props!.onToggleCollapse?.(group);
              }}
            />
            {group.status === 'Running' ? (
              <Loader size={SpinnerSize.small} style={{ padding: 5 }} />
            ) : (
              <Icon
                iconName={group.stateIconName}
                styles={{
                  root: {
                    fontSize: 18,
                    color: group.stateColor,
                    padding: '2px 4px',
                    borderRadius: 50,
                  },
                }}
              />
            )}
            <div className={classNames.groupLabel}>
              <h3 className={classNames.groupLabelTitle}>{group.name}</h3>
              <p className={classNames.groupLabelStatus}>
                {group.status === 'Finished' ? `Finished in ${group.duration}` : group.status}
              </p>
            </div>
          </div>
        );
      },
    },
    onRenderRow: IRenderFunction<IDetailsRowProps> = (props) => (
      <div className={classNames.executionStepOutputContainer}>
        {props!.item?.runnableOutput ? (
          <CodeBlock
            styles={{
              root: { borderRadius: 4 },
              pre: { borderRadius: 4 },
            }}
          >
            {props!.item.runnableOutput}
          </CodeBlock>
        ) : props!.item?.workflowExecution ? (
          <Button
            text="Go to execution detail"
            className={classNames.goToDetailButton}
            onClick={() => history.push(`/orchestrator/${props!.item.workflowExecution}`)}
          />
        ) : (
          <div className={classNames.noOutputMessage}>{props!.item?.statusMsg}</div>
        )}
      </div>
    ),
    detailItems = React.useMemo(
      () =>
        (executionDetail?.stepExecutions || []).map((stepExecution) => ({
          title: stepExecution.uniqueId,
          runnableOutput: stepExecution.runnableExecution?.stdout,
          workflowExecution: stepExecution.workflowExecution?.workflowExecution,
          statusMsg: getStateProps(stepExecution.state).message,
        })),
      [executionDetail]
    ),
    rowColumns = [
      {
        key: 'executions',
        name: '',
        minWidth: 350,
      },
    ];

  React.useEffect(() => {
    if (executionsItems) {
      const item = executionsItems?.find((item) => item.name === `${workflow?.name}/executions/${params.item_name}`);
      if (item) {
        setExecutionDetail(item);
      } else {
        setExecutionDetail(undefined);
      }
    }
  }, [params.item_name, executionsItems]);

  React.useEffect(() => {
    if (workflow?.name) void fetchExecutions();
  }, [workflow?.name]);

  React.useEffect(() => {
    setExecutionsItems((items) =>
      items?.[0]?.viewOnly === !permissions.canRunWorkflows
        ? items
        : items?.map((item) => ({
            ...item,
            viewOnly: !permissions.canRunWorkflows,
          }))
    );
  }, [permissions]);

  React.useEffect(() => {
    setDetailGroups(getDetailGroups(executionDetail, searchItemsNameMappings));
  }, [executionDetail]);

  React.useEffect(() => {
    // Expand group if href is set to a specific group.
    if (location.hash) {
      setDetailGroups((prevGroups) =>
        prevGroups?.map((group) => {
          if (group.key === location.hash.slice(1)) {
            return { ...group, isCollapsed: false };
          }
          return group;
        })
      );
    }
  }, [location]);

  return (
    <div className={classNames.contentWrapper}>
      {executionDetail ? (
        <div className={classNames.executionDetailContainer}>
          {workflow ? (
            <>
              <div className={classNames.executionDetailHeader}>
                <Button
                  iconName="Back"
                  styles={buttonStylesIcon}
                  text="Back to all workflow executions"
                  onClick={() => {
                    setExecutionDetail(undefined);
                    history.push(`/orchestrator/${workflow?.name}/executions`);
                  }}
                />
                <Toggle
                  inlineLabel
                  label="Visual representation"
                  checked={showVisualDetail}
                  onClick={() => setShowVisualDetail(!showVisualDetail)}
                  styles={{ root: { marginRight: 20 } }}
                />
              </div>
              <ListRow
                columns={listRowColumns}
                contents={
                  <div className={classNames.detailsListWrapper}>
                    {showVisualDetail ? (
                      <WorkflowExecutionFlowView
                        workflow={workflow}
                        executionDetail={executionDetail}
                        detailGroups={detailGroups}
                      />
                    ) : null}
                    <DetailsList
                      columns={rowColumns}
                      groups={detailGroups}
                      items={detailItems}
                      isHeaderVisible={false}
                      groupProps={groupProps}
                      onRenderRow={onRenderRow}
                      styles={{
                        root: {
                          borderTop: showVisualDetail ? '1px dashed var(--h2o-gray300)' : undefined,
                        },
                      }}
                    />
                  </div>
                }
                data={executionDetail}
                styles={getListRowStyles(theme)}
              />
            </>
          ) : (
            NoItemView({
              title: 'Failed to load workflow execution',
              description: 'Try to refresh the page. If the problem persists, contact our support.',
            })
          )}
        </div>
      ) : (
        <WidgetList
          title={`Executions of ${workflow?.displayName}`}
          columns={executionColumns}
          items={executionsItems}
          loading={loading}
          NoItemsContent={NoItemView({
            title: 'No workflow executions yet',
            description: 'All workflow executions will be listed here.',
          })}
          ErrorContent={FailedToLoadView({
            title: 'Failed to load workflow executions',
            description: 'Please try again later. If the problem persists, contact our support.',
            actionTitle: 'Retry',
            onActionClick: fetchExecutions,
            actionIcon: 'Refresh',
          })}
        />
      )}
    </div>
  );
};

export default WorkflowTabExecutions;
