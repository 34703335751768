import { IButtonStyles, INavLink, IStyle, KeyCodes, Stack } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuListProps } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Button, IH2OTheme, IStyleFunctionOrObject, Link, Loader, Nav, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { stackStylesPage } from '../../themes/themes';
import { useLeftPanel } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { ENDPOINTS } from './apiEndpoints';
import { Permissions, useRoles } from './RoleProvider';
import { useWorkspaces } from './WorkspaceProvider';

interface INavigationStyles {
  workspaceWidget: IStyle;
  widgetTitle: IStyle;
  contextualMenu: IStyle;
}

interface INavigationWrapperStyles {
  container: IStyle;
  stack: IStyle;
  noPermissions: IStyle;
}

const navigationStyles = (theme: IH2OTheme): Partial<INavigationStyles> => ({
    workspaceWidget: {
      backgroundColor: theme.semanticColors?.bodyBackground,
      padding: '20px 10px',
      borderRadius: 8,
      position: 'absolute',
      bottom: 40,
      maxWidth: 246,
    },
    widgetTitle: { color: theme.semanticColors?.messageBarTitleText, marginTop: 0 },
    contextualMenu: { width: 226 },
  }),
  navigationWrapperStyles = (): Partial<INavigationWrapperStyles> => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    noPermissions: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
  }),
  filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  },
  accessControlStyles: IStyleFunctionOrObject<IButtonStyles> | IStyleFunctionOrObject<IButtonStyles>[] | undefined = {
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 18,
    },
    root: {
      height: 20,
      minHeight: 20,
      padding: '0 8px',
      border: 'none',
    },
  },
  getRoutes = (workspaceName: string, permissions: Permissions) => {
    const routes: INavLink[] = [
      {
        key: 'home',
        name: 'Home',
        url: `/orchestrator/${workspaceName}`,
        icon: 'Home',
      },
    ];
    if (permissions.canViewRunnables) {
      routes.push(
        {
          key: 'executorPools',
          name: 'Executor pools',
          url: `/orchestrator/${workspaceName}${ENDPOINTS.EXECUTOR_POOLS}`,
          icon: 'Devices2',
        },
        {
          key: 'runnables',
          name: 'Runnables',
          url: `/orchestrator/${workspaceName}${ENDPOINTS.RUNNABLES}`,
          icon: 'Rocket',
        }
      );
    }
    if (permissions.canViewWorkflows) {
      routes.push(
        {
          key: 'workflows',
          name: 'Workflows',
          url: `/orchestrator/${workspaceName}${ENDPOINTS.WORKFLOWS}`,
          icon: 'Flow',
        },
        {
          key: 'executions',
          name: 'Active executions',
          url: `/orchestrator/${workspaceName}${ENDPOINTS.ACTIVE_EXECUTIONS}`,
          icon: 'Play',
        }
      );
    }
    return routes;
  };

const Navigation = ({
  workspaces,
  activateWorkspace,
  activeWorkspace,
  permissions,
}: {
  activeWorkspace?: Workspace;
  workspaces?: Workspace[];
  activateWorkspace: (name: string) => void;
  permissions: Permissions;
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string>(),
    history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationStyles, ClassNamesFromIStyles<INavigationStyles>>(
      'navigationPane',
      navigationStyles(theme)
    ),
    menuItems: IContextualMenuItem[] = React.useMemo(
      () =>
        workspaces?.map((w) => ({
          key: w.name || '',
          text: w.displayName,
          iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
          onClick: () => activateWorkspace(w.name || ''),
        })) || [],
      [activateWorkspace, workspaces, activeWorkspace]
    ),
    [items, setItems] = React.useState(menuItems),
    linkClickHandler = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      const { key, url } = item || {};
      if (!ev || !key || !url) return;
      ev.preventDefault(); // Prevents page reload.
      setSelectedKey(key);
      history.push(url);
    },
    [sideNavRoutes, setSideNavRoutes] = React.useState<INavLink[]>([]),
    onAbort = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    onChange = React.useCallback(
      (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const filteredItems = menuItems.filter(
          (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );

        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: 'no_results',
            onRender: (_item, _dismissMenu) => (
              <div key="no_results" style={filteredItemsStyle}>
                <Icon iconName="SearchIssue" title="No actions found" />
                <span>No workspace found</span>
              </div>
            ),
          });
        }

        setItems(filteredItems);
      },
      [menuItems]
    ),
    onKeyDown = React.useCallback((e) => {
      /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
        e.stopPropagation();
      }
      /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart !== e.target.value.length && e.which === KeyCodes.down) {
        e.stopPropagation();
      }
    }, []),
    onDismiss = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    renderMenuList = React.useCallback(
      (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div className={classNames.contextualMenu}>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter workspaces by text"
                placeholder="Filter workspaces"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender?.(menuListProps)}
            <br />
            <Link
              style={{ marginLeft: 12 }}
              onClick={() => {
                history.push('/orchestrator/workspaces');
                setSelectedKey('');
              }}
            >
              Show all workspaces
            </Link>
          </div>
        );
      },
      [onAbort, onChange, onKeyDown]
    ),
    menuProps = React.useMemo(
      () => ({
        onRenderMenuList: renderMenuList,
        shouldFocusOnMount: true,
        items,
        focusZoneProps: {
          /* Allow up and down arrows to move focus out of the SearchBox */
          shouldInputLoseFocusOnArrowKey: () => true,
        },
        onDismiss,
      }),
      [items, renderMenuList, onDismiss]
    );

  React.useEffect(() => {
    const newItems: IContextualMenuItem[] =
      workspaces?.map((w) => ({
        key: w.name || '',
        text: w.displayName,
        iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
        onClick: () => activateWorkspace(w.name || ''),
      })) || [];
    setItems(newItems);
  }, [workspaces, activeWorkspace, activateWorkspace]);

  React.useEffect(() => {
    const afterWorkspaces = history.location.pathname?.split('workspaces/')?.[1],
      item = afterWorkspaces?.split('/')?.[1],
      keyMap = {
        workflows: 'workflows',
        executorPools: 'executorPools',
        runnables: 'runnables',
        activeExecutions: 'executions',
        default: afterWorkspaces?.split('/')?.length >= 1 ? 'home' : '',
      },
      newKey = keyMap[item] ?? keyMap.default;

    if (selectedKey !== newKey) setSelectedKey(newKey);
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (activeWorkspace?.name) setSideNavRoutes(getRoutes(activeWorkspace.name, permissions));
  }, [permissions, activeWorkspace?.name]);

  return (
    <>
      <Nav groups={[{ links: sideNavRoutes }]} onLinkClick={linkClickHandler} selectedKey={selectedKey} />
      <div className={classNames.workspaceWidget}>
        <h4 className={classNames.widgetTitle}>Active workspace</h4>
        <Button
          iconName="Stack"
          text={activeWorkspace?.displayName || 'No workspace selected'}
          menuIconName="ChevronDown"
          menuProps={menuProps}
          styles={{ root: { width: '226px' } }}
        />
        <Button
          text="Access control"
          onClick={() => {
            history.push(`/orchestrator/${activeWorkspace?.name}/accessControl`);
          }}
          iconName="AddGroup"
          styles={accessControlStyles}
          style={{ visibility: permissions.canViewAccessControl ? 'visible' : 'hidden' }}
          buttonContainerStyles={{ root: { marginTop: 12, textAlign: 'center' } }}
        />
      </div>
    </>
  );
};

const NavigationWrapper: React.FC = ({ children }) => {
  const setLeftPanelProps = useLeftPanel(),
    { ACTIVE_WORKSPACE_NAME, workspaces, activateWorkspace, activeWorkspace } = useWorkspaces(),
    { permissions, loadingPermissions } = useRoles(),
    history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationWrapperStyles, ClassNamesFromIStyles<INavigationWrapperStyles>>(
      'navigationWrapper',
      navigationWrapperStyles
    ),
    timeoutRef = React.useRef<number>(),
    [loading, setLoading] = React.useState(true),
    [showLoader, setShowLoader] = React.useState<boolean>(false),
    [restrictAccess, setRestrictAccess] = React.useState(true);

  React.useEffect(() => {
    if (!ACTIVE_WORKSPACE_NAME) return;
    setLeftPanelProps({
      content: (
        <Navigation
          activeWorkspace={activeWorkspace}
          workspaces={workspaces}
          activateWorkspace={activateWorkspace}
          permissions={permissions}
        />
      ),
      styles: {
        root: {
          padding: 12,
          height: 'calc(100% - 156px)', // 96 header height + 60 footer height = 156
          backgroundColor: theme.semanticColors?.contentBackground,
          boxShadow: '6px 0px 10px -6px var(--h2o-gray400_a_16, #1C1D2129)',
        },
      },
    });
    return () => setLeftPanelProps({ content: undefined });
  }, [setLeftPanelProps, ACTIVE_WORKSPACE_NAME, activateWorkspace, workspaces, activeWorkspace, permissions]);

  React.useEffect(() => {
    if (!loadingPermissions) {
      const pathsPermissions = [
        { path: 'accessControl', permission: permissions.canViewAccessControl },
        { path: 'workflows', permission: permissions.canViewWorkflows },
        { path: 'executorPools', permission: permissions.canViewRunnables },
        { path: 'runnables', permission: permissions.canViewRunnables },
        { path: 'activeExecutions', permission: permissions.canViewWorkflows },
        { path: 'executions', permission: permissions.canViewWorkflows },
      ];

      const restrict = pathsPermissions.some(
        ({ path, permission }) => history.location.pathname?.includes(path) && !permission
      );

      setRestrictAccess(restrict);
      setLoading(false);
    }
  }, [history.location.pathname, loadingPermissions, permissions]);

  React.useEffect(() => {
    if (loading) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading]);

  return (
    <div className={classNames.container}>
      <Stack styles={stackStylesPage} className={classNames.stack}>
        {restrictAccess ? (
          <div className={classNames.noPermissions}>
            {loading ? (
              showLoader ? (
                <Loader label="Just a moment, checking your permissions" />
              ) : null
            ) : (
              `You have no access to this page.\nAsk the workspace owner to grant you permissions.`
            )}
          </div>
        ) : (
          children
        )}
      </Stack>
    </div>
  );
};

export default NavigationWrapper;
