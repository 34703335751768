import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { useAuthzService } from '../../authz/hooks';
import { Preferences } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/preferences_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { useUser } from '../../utils/hooks';
import { NoItemView } from './NoItemView';

type WorkspaceContextType = {
  workspaces?: Workspace[];
  activeWorkspace?: Workspace;
  ACTIVE_WORKSPACE_NAME?: string;
  loading?: boolean;
  activateWorkspace: (name: string) => void;
  fetchWorkspaces: () => void;
  createWorkspace: (displayName: string) => void;
};

type SwitcherProps = {
  currentWorkspace: string;
  destinationWorkspace: string;
  activateDestinationWorkspace: () => void;
};

const WorkspaceContext = React.createContext<WorkspaceContextType | undefined>(undefined);

const getWorkspaceFromUrl = (pathname: string) => {
  return pathname.includes('/orchestrator/workspaces/') && !pathname.endsWith('workspaces/')
    ? `workspaces/${pathname.replace('/orchestrator/', '').split('/')[1]}`
    : undefined;
};

const WorkspaceProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation(),
    authzService = useAuthzService(),
    orchestratorService = useOrchestratorService(),
    history = useHistory(),
    user = useUser(),
    { addToast } = useToast(),
    { pathname } = location,
    [activeWorkspace, setActiveWorkspace] = React.useState<Workspace | undefined>(),
    skipProceedDialogRef = React.useRef(false),
    loadStateRef = React.useRef({
      fetchWorkspaces: false,
      fetchPreferences: false,
      createWorkspace: false,
      updatePreferences: false,
    }),
    [loading, setLoading] = React.useState(true),
    [preferences, setPreferences] = React.useState<Preferences | undefined>(),
    [workspaces, setWorkspaces] = React.useState<Workspace[]>(),
    [workspaceSwitcherProps, setWorkspaceSwitcherProps] = React.useState<SwitcherProps>(),
    [workspaceNotFound, setWorkspaceNotFound] = React.useState(false),
    workspaceNamesToDisplayNames: { [key: string]: string } = React.useMemo(
      () =>
        workspaces
          ? workspaces.reduce(
              (acc, w) => ({ ...acc, [w.name || '']: w.displayName || '' }),
              {} as { [key: string]: string }
            )
          : {},
      [workspaces]
    ),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchWorkspaces &&
        !loadStateRef.current.fetchPreferences &&
        !loadStateRef.current.createWorkspace &&
        !loadStateRef.current.updatePreferences
      ) {
        setLoading(false);
      }
    },
    fetchWorkspaces = React.useCallback(async () => {
      loadStateRef.current.fetchWorkspaces = true;
      setLoading(true);
      try {
        const data = await authzService.getWorkspaces({});
        const workspaceItems: Workspace[] | undefined = data?.workspaces;
        if (data && !workspaceItems) console.error('No workspaces found in the response.');
        setWorkspaces(workspaceItems);
      } catch (err) {
        const message = `Failed to fetch workspaces: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkspaces(undefined);
      } finally {
        loadStateRef.current.fetchWorkspaces = false;
        evaluateLoading();
      }
    }, [authzService, addToast]),
    fetchPreferences = React.useCallback(async () => {
      loadStateRef.current.fetchPreferences = true;
      setLoading(true);
      try {
        const data = await orchestratorService.getPreferences({ name: `users/${user.id}/preferences` });
        setPreferences(data);
      } catch (err) {
        const message = `Failed to fetch preferences: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setPreferences(undefined);
        setActiveWorkspace(undefined);
      } finally {
        loadStateRef.current.fetchPreferences = false;
        evaluateLoading();
      }
    }, [orchestratorService, user, addToast]),
    updatePreferences = React.useCallback(
      async (lastActiveWorkspace: string, toDashboard = false) => {
        loadStateRef.current.updatePreferences = true;
        setLoading(true);
        try {
          await orchestratorService.updatePreferences({
            preferences: {
              name: `users/${user.id}/preferences`,
              lastActiveWorkspace,
            },
            updateMask: 'lastActiveWorkspace',
          });

          setActiveWorkspace({
            name: lastActiveWorkspace,
            displayName: workspaceNamesToDisplayNames[lastActiveWorkspace],
          });
          if (toDashboard) {
            history.push(`/orchestrator/${lastActiveWorkspace}`);
          } else if (pathname.startsWith('/orchestrator/workspaces/') && !pathname.endsWith('workspaces/')) {
            const pathnameEnd = pathname.replace('/orchestrator/workspaces/', '').split(/\/(.*)/s)[1];
            const path = pathnameEnd?.endsWith('/create-new') ? pathnameEnd.replace('/create-new', '') : pathnameEnd;
            history.push(`/orchestrator/${lastActiveWorkspace}${path ? `/${path}` : ''}`);
          }
        } catch (err) {
          const message = `Failed to update preferences: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.updatePreferences = false;
          evaluateLoading();
          void fetchPreferences();
          skipProceedDialogRef.current = false;
        }
      },
      [pathname, history, workspaceNamesToDisplayNames, user, addToast, orchestratorService, fetchPreferences]
    ),
    activateWorkspace = React.useCallback(
      (name: string) => {
        if (!workspaces) {
          const message = `Workspaces are not loaded yet.`;
          console.error(message);
          addToast({ messageBarType: MessageBarType.error, message });
          return;
        }
        const workspace = workspaces?.find((w) => w.name === name);
        if (workspace) {
          if (preferences && name !== preferences?.lastActiveWorkspace) {
            skipProceedDialogRef.current = true;
            void updatePreferences(name);
          }
        } else {
          const message = `Workspace you are trying to activate was not found.`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [workspaces, preferences, updatePreferences]
    ),
    createWorkspace = React.useCallback(
      async (displayName: string) => {
        loadStateRef.current.createWorkspace = true;
        setLoading(true);
        try {
          const data = await authzService.createWorkspace({ workspace: { displayName } });
          const workspaceName = data?.workspace?.name;
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Workspace created successfully.',
          });
          await fetchWorkspaces();
          skipProceedDialogRef.current = true;
          if (workspaceName) updatePreferences(workspaceName, true);
        } catch (err) {
          const message = `Failed to create workspace: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.createWorkspace = false;
          evaluateLoading();
        }
      },
      [fetchWorkspaces, updatePreferences]
    );

  React.useEffect(() => {
    void fetchWorkspaces();
    void fetchPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!loading && workspaces && preferences && activeWorkspace?.name !== preferences.lastActiveWorkspace) {
      const workspaceNameFromUrl = getWorkspaceFromUrl(pathname),
        isWorkspaceFromUrlAvailable = workspaceNameFromUrl && workspaces.find((w) => w.name === workspaceNameFromUrl);
      if (
        isWorkspaceFromUrlAvailable &&
        workspaceNameFromUrl !== preferences.lastActiveWorkspace &&
        !skipProceedDialogRef.current
      ) {
        setWorkspaceSwitcherProps({
          currentWorkspace: workspaceNamesToDisplayNames[preferences.lastActiveWorkspace] || '',
          destinationWorkspace: workspaceNamesToDisplayNames[workspaceNameFromUrl] || '',
          activateDestinationWorkspace: () => {
            activateWorkspace(workspaceNameFromUrl);
            setWorkspaceSwitcherProps(undefined);
          },
        });
      } else {
        if (preferences.lastActiveWorkspace) {
          setActiveWorkspace(workspaces.find((w) => w.name === preferences.lastActiveWorkspace));
        }
        if (workspaceNameFromUrl && !isWorkspaceFromUrlAvailable) setWorkspaceNotFound(true);
      }
    }
  }, [workspaces, preferences, pathname, loading, activeWorkspace]);

  const value = {
    workspaces,
    activeWorkspace,
    activateWorkspace,
    ACTIVE_WORKSPACE_NAME: activeWorkspace?.name,
    loading,
    fetchWorkspaces,
    createWorkspace,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {workspaceSwitcherProps ? (
        <NoItemView
          actionIcon="NavigateForward"
          title={`The URL is not from your active workspace`}
          description={`Do you want to leave ${workspaceSwitcherProps.currentWorkspace} and activate ${workspaceSwitcherProps.destinationWorkspace}?`}
          actionTitle={`Proceed to ${workspaceSwitcherProps.destinationWorkspace}`}
          onActionClick={workspaceSwitcherProps.activateDestinationWorkspace}
        />
      ) : workspaceNotFound ? (
        <NoItemView
          actionIcon="NavigateForward"
          title="Workspace not found"
          description="The workspace you are trying to access does not exist or you do not have access to it."
          actionTitle="Go to workspace selection"
          onActionClick={() => {
            setWorkspaceNotFound(false);
            history.push('/orchestrator/workspaces');
          }}
        />
      ) : (
        children
      )}
    </WorkspaceContext.Provider>
  );
};

const useWorkspaces = () => {
  const context = React.useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspaces must be used within a WorkspaceProvider');
  }
  return context;
};

export { WorkspaceProvider, useWorkspaces };
