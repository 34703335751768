import { IButtonStyles, Stack } from '@fluentui/react';
import {
  Button,
  FluentClickHandler,
  FontSizes,
  IH2OTheme,
  IconName,
  buttonStylesIcon,
  buttonStylesIconLike,
  buttonStylesIconLiked,
  buttonStylesIconPin,
  buttonStylesIconPinned,
} from '@h2oai/ui-kit';

import { AppPreference } from '../../ai.h2o.cloud.appstore';

const buttonStylesAppDetailIcon = (theme: IH2OTheme): Partial<IButtonStyles> => ({
  root: {
    height: 24,
    padding: 7,
    backgroundColor: theme.palette?.gray300_a_50,
  },
  rootHovered: {
    backgroundColor: theme.palette?.gray200,
    color: theme.palette?.gray900,
  },
  rootPressed: {
    backgroundColor: theme.palette?.gray300,
    color: theme.palette?.gray900,
  },
  rootFocused: {
    backgroundColor: theme.palette?.gray300,
    color: theme.palette?.gray900,
  },
  icon: {
    fontSize: FontSizes.xsmall,
  },
});

const buttonStylesAppDetailPinIcon: Partial<IButtonStyles> = {
  icon: {
    margin: 0,
  },
};

type AppSocialInfoProps = AppPreference & {
  onPin: FluentClickHandler;
  onLike: FluentClickHandler;
  publicMode?: boolean;
};

export function AppSocialInfo({ pinned, liked, likes, onPin, onLike, publicMode = false }: AppSocialInfoProps) {
  const height = 20;

  return (
    <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { height } }}>
      <Button
        styles={[
          buttonStylesIcon,
          pinned ? buttonStylesIconPinned : buttonStylesIconPin,
          buttonStylesAppDetailIcon,
          buttonStylesAppDetailPinIcon,
        ]}
        iconName={IconName.PinnedSolid}
        title="Pin"
        onClick={onPin}
        disabled={publicMode}
      />
      <Stack horizontal styles={{ root: { height } }}>
        <Button
          styles={[buttonStylesIcon, liked ? buttonStylesIconLiked : buttonStylesIconLike, buttonStylesAppDetailIcon]}
          data-test="likes-button"
          text={likes?.toString()}
          iconName={liked ? IconName.HeartFill : IconName.Heart}
          onClick={onLike}
          disabled={publicMode}
        />
      </Stack>
    </Stack>
  );
}
