// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/workflow_execution_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { WorkflowExecution } from "./workflow_execution_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for DeleteWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowExecutionRequest
 */
export type DeleteWorkflowExecutionRequest = {
/**
 * Required. WorkflowExecution resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/executions/{execution}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowExecutionResponse
 */
export type DeleteWorkflowExecutionResponse = {
}
;
/**
 * Request message for GetWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowExecutionRequest
 */
export type GetWorkflowExecutionRequest = {
/**
 * Required. WorkflowExecution resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/executions/{execution}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowExecutionResponse
 */
export type GetWorkflowExecutionResponse = {
/**
 * The requested WorkflowExecution.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution workflow_execution = 1;
 */
workflowExecution?: WorkflowExecution;
}
;
/**
 * Request message for ListWorkflowExecutions.
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowExecutionsRequest
 */
export type ListWorkflowExecutionsRequest = {
/**
 * Required. The resource name of the Workflow to list executions from.
 * Format: `workspaces/{workspace}/workflows/{workflow}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return workflow executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * state
 *   * start_time
 *   * end_time
 *
 * Examples:
 * state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z
 * start_time >= 2023-12-30T23:59:43Z AND end_time <= 2024-03-20T08:00:00Z
 * NOT(state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z) AND (state = 'STATE_RUNNING')
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workflow executions ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListWorkflowExecutions
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowExecutionsResponse
 */
export type ListWorkflowExecutionsResponse = {
/**
 * Collection of workflow executions.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.WorkflowExecution workflow_executions = 1;
 */
workflowExecutions?: WorkflowExecution[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for CancelWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.CancelWorkflowExecutionRequest
 */
export type CancelWorkflowExecutionRequest = {
/**
 * Required. WorkflowExecution resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/executions/{execution}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for CancelWorkflowExecution
 *
 * @generated from message ai.h2o.orchestrator.v1.CancelWorkflowExecutionResponse
 */
export type CancelWorkflowExecutionResponse = {
}
;
/**
 * Request message for ListWorkspaceExecutions
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkspaceWorkflowExecutionsRequest
 */
export type ListWorkspaceWorkflowExecutionsRequest = {
/**
 * Required. The resource name of the Workflow to list executions from.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return workflow executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * state
 *   * start_time
 *   * end_time
 *
 * Examples:
 * state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z
 * start_time >= 2023-12-30T23:59:43Z AND end_time <= 2024-03-20T08:00:00Z
 * NOT(state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z) AND (state = 'STATE_RUNNING')
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workflow executions ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListWorkspaceExecutions
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkspaceWorkflowExecutionsResponse
 */
export type ListWorkspaceWorkflowExecutionsResponse = {
/**
 * Collection of all workspace executions.
 * (-- api-linter: core::0132::response-unknown-fields=disabled
 *     aip.dev/not-precedent: We really need this field to return all workflow executions by workspace. --)
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.WorkflowExecution workflow_executions = 1;
 */
workflowExecutions?: WorkflowExecution[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Deletes a WorkflowExecution.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowExecutionService.DeleteWorkflowExecution
 */
export const WorkflowExecutionService_DeleteWorkflowExecution = new RPC<DeleteWorkflowExecutionRequest, DeleteWorkflowExecutionResponse>("DELETE", "/v1/{name=workspaces/*/workflows/*/executions/*}");
/**
 * Returns a specific WorkflowExecution.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowExecutionService.GetWorkflowExecution
 */
export const WorkflowExecutionService_GetWorkflowExecution = new RPC<GetWorkflowExecutionRequest, GetWorkflowExecutionResponse>("GET", "/v1/{name=workspaces/*/workflows/*/executions/*}");
/**
 * Returns a collection of WorkflowExecution for given workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowExecutionService.ListWorkflowExecutions
 */
export const WorkflowExecutionService_ListWorkflowExecutions = new RPC<ListWorkflowExecutionsRequest, ListWorkflowExecutionsResponse>("GET", "/v1/{parent=workspaces/*/workflows/*}/executions");
/**
 * Cancel started WorkflowExecution
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowExecutionService.CancelWorkflowExecution
 */
export const WorkflowExecutionService_CancelWorkflowExecution = new RPC<CancelWorkflowExecutionRequest, CancelWorkflowExecutionResponse>("POST", "/v1/{name=workspaces/*/workflows/*/executions/*}:cancel");
/**
 * Returns a collection of WorkflowExecutions for given workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowExecutionService.ListWorkspaceWorkflowExecutions
 */
export const WorkflowExecutionService_ListWorkspaceWorkflowExecutions = new RPC<ListWorkspaceWorkflowExecutionsRequest, ListWorkspaceWorkflowExecutionsResponse>("GET", "/v1/{parent=workspaces/*}/executions");
