import { Item, itemStylesTag } from '@h2oai/ui-kit';

import { WidgetIconButton } from './Runnables';
import WidgetList from './WidgetList';

type Trigger = {
  id: string;
  title: string;
  description: string;
  status: string;
  scheduledAt: string;
  createdAt: string;
  creator: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
};

const onEdit = (id: string) => {
    alert(id);
    // TODO: Implement editing.
  },
  onDelete = (id: string) => {
    alert(id);
    // TODO: Implement deletion.
  },
  Tag = ({ title, color }: { title: string; color: string }) => (
    <Item
      data={{ title }}
      labelField="title"
      styles={[
        itemStylesTag,
        {
          root: {
            backgroundColor: color,
            width: 50,
            justifyContent: 'center',
          },
        },
      ]}
    />
  ),
  triggerColumns = [
    {
      key: 'title',
      name: 'Title',
      fieldName: 'description',
      minWidth: 180,
      maxWidth: 360,
      data: {
        headerFieldName: 'title',
        listCellProps: {
          iconProps: {
            iconName: 'ProjectCollection',
          },
        },
      },
    },
    {
      key: 'status',
      name: '',
      fieldName: 'status',
      minWidth: 90,
      maxWidth: 180,
      data: {
        listCellProps: {
          // TODO: Use theme colors.
          onRenderText: ({ status }: Trigger) => (
            <Tag title={status} color={status === 'Active' ? '#E9FFE6' : '#FFD9D9'} />
          ),
        },
      },
    },
    {
      key: 'scheduledAt',
      name: 'Scheduled At',
      fieldName: 'scheduledAt',
      minWidth: 90,
      maxWidth: 180,
    },
    {
      key: 'creator',
      name: 'Creator',
      fieldName: 'creator',
      minWidth: 90,
      maxWidth: 180,
    },
    {
      key: 'createdAt',
      name: 'Created At',
      fieldName: 'createdAt',
      minWidth: 90,
      maxWidth: 180,
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 140,
      maxWidth: 200,
      data: {
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderText: ({ id, onDelete, onEdit }: Trigger) => (
            <>
              <WidgetIconButton
                iconName="Delete"
                onClick={() => onDelete(id)}
                iconColor="white"
                title="Delete"
                backgroundColor="red"
                hasBorder={false}
              />
              <WidgetIconButton iconName="Edit" onClick={() => onEdit(id)} title="Edit" />
            </>
          ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
            },
          },
        },
      },
    },
  ],
  // TODO: Replace with actual data.
  triggerItems: Trigger[] = [
    {
      id: '1',
      title: 'Trigger 1',
      description: 'Some description',
      status: 'Active',
      createdAt: '2021-09-02',
      creator: 'user@h2o.ai',
      scheduledAt: 'Every 5 a.m. on Monday',
      onEdit: () => onEdit('1'),
      onDelete: () => onDelete('1'),
    },
    {
      id: '2',
      title: 'Trigger 2',
      description: 'Some description',
      status: 'Inactive',
      createdAt: '2021-09-02',
      creator: 'user@h2o.ai',
      scheduledAt: 'Every 5 a.m. on Monday',
      onEdit: () => onEdit('2'),
      onDelete: () => onDelete('2'),
    },
    {
      id: '3',
      title: 'Trigger 3',
      description: 'Some description',
      status: 'Inactive',
      createdAt: '2021-09-02',
      creator: 'user@h2o.ai',
      scheduledAt: 'Every 5 a.m. on Monday',
      onEdit: () => onEdit('3'),
      onDelete: () => onDelete('3'),
    },
    {
      id: '4',
      title: 'Trigger 4',
      description: 'Some description',
      status: 'Inactive',
      createdAt: '2021-09-02',
      creator: 'user@h2o.ai',
      scheduledAt: 'Every 5 a.m. on Monday',
      onEdit: () => onEdit('4'),
      onDelete: () => onDelete('4'),
    },
  ];

const WorkflowTabTriggers = () => (
  <WidgetList title="Triggers" columns={triggerColumns} items={triggerItems} loading={false} />
);

export default WorkflowTabTriggers;
