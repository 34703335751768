import NavigationWrapper from './NavigationWrapper';

const Dashboard = () => {
  return (
    <NavigationWrapper>
      <div style={{ padding: 20 }}>
        <h1>Dashboard</h1>
      </div>
    </NavigationWrapper>
  );
};

export default Dashboard;
