import { PanelType, Stack } from '@fluentui/react';
import { Dropdown, Panel } from '@h2oai/ui-kit';
import { useState } from 'react';

import { App, AppInstance_Visibility, LaunchProfiles, RunAppRequest } from '../../../../ai.h2o.cloud.appstore';
import { getOptionsFromEnum } from '../../../../aiem/entity/utils';
import { formatBytes, milliCPUToCPU } from '../../../../aiem/utils';
import { stylesPanel } from '../../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { LabelIconTooltip } from '../../../../components/AIEnginesPage/components/LabelIconTooltip/LabelIconTooltip';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../../../components/AIEnginesPage/components/MetadataTable/MetadataTable';
import { EditablePanelFooter } from '../../../../components/EditablePanelFooter/EditablePanelFooter';
import { FormRow } from '../../../AIEngineSettingsPage/components/BasicEntityModelComponents';

export interface IRunWithProfilePanelProps {
  app: App;
  profiles: LaunchProfiles[];
  onDismiss: () => void;
  onRun: (request: { app: App } & Partial<RunAppRequest>) => void;
}

export function RunWithProfilePanel(props: IRunWithProfilePanelProps) {
  const { app, profiles, onDismiss, onRun } = props;

  const [visibility, setVisibility] = useState<AppInstance_Visibility>(AppInstance_Visibility.PRIVATE),
    [profile, setProfile] = useState<Partial<LaunchProfiles>>(profiles[0]);

  const visibilities = getOptionsFromEnum(AppInstance_Visibility, '', [AppInstance_Visibility.VISIBILITY_UNSPECIFIED]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText="Run With Profile"
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <Stack style={{ marginBottom: 10 }}>
        <FormRow>
          <Dropdown
            label="Visibility"
            data-test="visibility-dropdown"
            selectedKey={visibility}
            options={visibilities}
            onChange={(_, v) => setVisibility(v?.key as AppInstance_Visibility)}
          />
        </FormRow>
        <FormRow>
          <Dropdown
            label="Profiles"
            data-test="profiles-dropdown"
            selectedKey={profile.name}
            options={profiles?.map((p) => ({ key: p.name, text: p.description, data: p }))}
            onChange={(_, v) => {
              setProfile(v?.data);
            }}
          />
        </FormRow>

        {profile && (
          <Stack tokens={{ childrenGap: 20 }}>
            <MetadataTable>
              <MetadataTableBody>
                <MetadataRow>
                  <MetadataLabelCell colspan={3}>Memory Limit</MetadataLabelCell>
                  <MetadataValueCell>
                    <LabelIconTooltip
                      id={'memory-limit'}
                      data-test="memory-limit"
                      label={formatBytes(Number(profile.memoryLimit))}
                    />
                  </MetadataValueCell>
                </MetadataRow>
                <MetadataRow>
                  <MetadataLabelCell colspan={3}>Memory Reservation</MetadataLabelCell>
                  <MetadataValueCell>
                    <LabelIconTooltip
                      id={'memory-reservation'}
                      data-test="memory-reservation"
                      label={formatBytes(Number(profile.memoryReservation))}
                    />
                  </MetadataValueCell>
                </MetadataRow>
                <MetadataRow>
                  <MetadataLabelCell colspan={3}>CPU Limit</MetadataLabelCell>
                  <MetadataValueCell>
                    <LabelIconTooltip
                      id={'cpu-limit'}
                      data-test="cpu-limit"
                      label={milliCPUToCPU(Number(profile.cpuLimit))}
                    />
                  </MetadataValueCell>
                </MetadataRow>
                <MetadataRow>
                  <MetadataLabelCell colspan={3}>CPU Reservation</MetadataLabelCell>
                  <MetadataValueCell>
                    <LabelIconTooltip
                      id={'cpu-limit'}
                      data-test="cpu-limit"
                      label={milliCPUToCPU(Number(profile.cpuReservation))}
                    />
                  </MetadataValueCell>
                </MetadataRow>
                {Boolean(profile?.gpuCount) && (
                  <MetadataRow>
                    <MetadataLabelCell colspan={3}>GPU Count</MetadataLabelCell>
                    <MetadataValueCell>
                      <LabelIconTooltip id={'gpu-count'} data-test="gpu-count" label={profile.gpuCount} />
                    </MetadataValueCell>
                  </MetadataRow>
                )}
              </MetadataTableBody>
            </MetadataTable>
          </Stack>
        )}
      </Stack>
      <EditablePanelFooter
        onCancel={onDismiss}
        onSave={() => {
          onRun({
            app,
            visibility,
            ...(profile && profile.name !== 'none' ? { profile: profile.name } : {}),
          });
        }}
        closeButtonText="Cancel"
        saveButtonText="Run With Profile"
        saveButtonDisabled={false}
      />
    </Panel>
  );
}
