import { ITextStyles } from '@fluentui/react';
import { FontSizes, mediaMobile } from '@h2oai/ui-kit';

import { stackStylesAppStorePage } from '../../themes/themes';

export const appStorePageStyles = (): Partial<ITextStyles> => ({
  root: {
    ...(stackStylesAppStorePage.root as object),

    // Search
    '.search-box-container': {
      ...mediaMobile({
        marginBottom: 10,
        '.ms-SearchBox': { height: 48 },
        input: { fontSize: FontSizes.small },
        i: { fontSize: FontSizes.xlarge },
      }),
    },

    // Grid
    '.card-grid': {
      ...mediaMobile({ margin: '0 -18px' }),
      '.h2o-CardList-root': {
        ...mediaMobile({
          display: 'flex',
          overflow: 'auto',
          margin: 0,
        }),
      },
      'section.AppStoreSection, section.app-section--detailed-listing': {
        ...mediaMobile({ marginBottom: 10 }),
        '> header': {
          ...mediaMobile({
            marginBottom: 11,
            padding: '0 18px',
          }),
        },
      },
      h1: {
        ...mediaMobile({
          padding: '0 !important',
          fontSize: '18px !important',
          lineHeight: '22px !important',
        }),
      },
      '.h2o-ButtonContainer-root .ms-Button': { ...mediaMobile({ minHeight: 0 }) },
      '.h2o-CardList-item': {
        ...mediaMobile({
          width: '37.5vw',
          minWidth: 160,
          maxWidth: 240,
          flexGrow: 0,
          flexShrink: 0,
          margin: 0,
        }),
      },
      '.h2o-CardList-item:first-child': { ...mediaMobile({ marginLeft: 18 }) },
      '.h2o-CardList-item:not(:last-child)': { ...mediaMobile({ marginRight: '1.6vw' }) },
      '.h2o-CardList-item .h2o-Card-content .h2o-Card-header': { ...mediaMobile({ marginBottom: 0 }) },
      '.h2o-CardList-item .h2o-Card-thumbnailImage': { ...mediaMobile({ marginBottom: 8 }) },
      '.h2o-CardList-item .h2o-Card-thumbnailImage:after': { ...mediaMobile({ paddingBottom: '95%' }) },
      '.h2o-Card-headerTitle': {
        ...mediaMobile({
          fontSize: FontSizes.xsmall,
          lineHeight: FontSizes.medium,
        }),
      },
      '.h2o-Card-thumbnailBadgeList': { ...mediaMobile({ paddingTop: 8 }) },
    },
  },
});
