import { IDropdownOption, ITextFieldProps, Label, MessageBarType, PanelType } from '@fluentui/react';
import {
  Button,
  Dropdown,
  Info,
  Panel,
  TextField,
  buttonStylesGhost,
  buttonStylesPrimary,
  useClassNames,
  useToast,
} from '@h2oai/ui-kit';
import Form from '@rjsf/fluent-ui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ExecutorPool } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import Editor from './Editor';
import { IExecutorPoolDetailStyles, executorPoolDetailStyles } from './ExecutorPoolDetail.styles';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import { checkIfNatural } from './WorkflowTabCanvas';
import { useWorkspaces } from './WorkspaceProvider';

export type DropdownOption = { key: string | number; text: string; executorPoolSpecSchema: RJSFSchema };

const ExecutorPoolDetail = () => {
  const location = useLocation(),
    history = useHistory(),
    orchestratorService = useOrchestratorService(),
    classNames = useClassNames<IExecutorPoolDetailStyles, ClassNamesFromIStyles<IExecutorPoolDetailStyles>>(
      'executorPoolDetail',
      executorPoolDetailStyles
    ),
    { addToast } = useToast(),
    { permissions } = useRoles(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    executorPool: ExecutorPool = (location.state as any)?.state as ExecutorPool,
    isNew = !executorPool,
    // TODO: Handle loading state
    [, setLoading] = React.useState(true),
    [displayName, setDisplayName] = React.useState(executorPool?.displayName || ''),
    [executorPoolSpec, setExecutorPoolSpec] = React.useState(executorPool?.executorPoolSpec || ''),
    [executorPoolConfig, setExecutorPoolConfig] = React.useState(JSON.parse(executorPool?.executorPoolConfig || '{}')),
    [maxQueueSizePerExecutor, setMaxQueueSizePerExecutor] = React.useState(executorPool?.maxQueueSizePerExecutor),
    [maxExecutors, setMaxExecutors] = React.useState(executorPool?.maxExecutors),
    [showExecutorPoolValidation, setShowExecutorPoolValidation] = React.useState(false),
    [poolSpecOptions, setPoolSpecOptions] = React.useState<DropdownOption[]>([]),
    [isSpecDialogOpen, setIsSpecDialogOpen] = React.useState(false),
    [currentSchema, setCurrentSchema] = React.useState<RJSFSchema>(),
    fetchExecutorPoolSpecs = React.useCallback(async () => {
      try {
        const data = await orchestratorService.getExecutorPoolSpecs({});
        const options = data?.executorPoolSpecs
          ? data.executorPoolSpecs.map((spec) => ({
              key: spec.name || '',
              text: spec.displayName || '',
              executorPoolSpecSchema: JSON.parse(spec.executorPoolSpecSchema || '{}'),
            }))
          : [];
        setPoolSpecOptions(options);
      } catch (err) {
        const message = `Failed to fetch executorPoolSpecs: ${err}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        console.error(message);
      }
    }, [orchestratorService, addToast]),
    createExecutorPool = React.useCallback(async () => {
      try {
        await orchestratorService.createExecutorPool({
          parent: ACTIVE_WORKSPACE_NAME || '',
          executorPool: {
            displayName,
            executorPoolSpec,
            executorPoolConfig: JSON.stringify(executorPoolConfig),
            maxQueueSizePerExecutor,
            maxExecutors,
          },
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Executor pool created successfully.',
        });
        history.goBack();
      } catch (err) {
        const message = `Failed to create executor pool: ${err}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        console.error(message);
      } finally {
        setLoading(false);
      }
    }, [
      ACTIVE_WORKSPACE_NAME,
      orchestratorService,
      addToast,
      history,
      // TODO: Set through params.
      displayName,
      executorPoolSpec,
      executorPoolConfig,
      maxQueueSizePerExecutor,
      maxExecutors,
    ]),
    updateExecutorPool = React.useCallback(
      async (name: string) => {
        setLoading(true);
        try {
          await orchestratorService.editExecutorPool({
            executorPool: {
              name,
              displayName,
              executorPoolSpec,
              executorPoolConfig: JSON.stringify(executorPoolConfig),
              maxQueueSizePerExecutor,
              maxExecutors,
            },
            updateMask: 'displayName,executorPoolSpec,executorPoolConfig,maxQueueSizePerExecutor,maxExecutors',
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: `${displayName || 'Executor pool'} updated successfully.`,
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to update ${displayName || 'executor pool'}: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
        }
      },
      [
        orchestratorService,
        addToast,
        history,
        // TODO: Set through params.
        displayName,
        executorPoolSpec,
        executorPoolConfig,
        maxQueueSizePerExecutor,
        maxExecutors,
      ]
    ),
    onActionClick = () => {
      if (
        !displayName ||
        !executorPoolSpec ||
        !executorPoolConfig ||
        (currentSchema && !validator.isValid(currentSchema, executorPoolConfig, {}))
      ) {
        setShowExecutorPoolValidation(true);
        return;
      }
      if (isNew) void createExecutorPool();
      else void updateExecutorPool(executorPool?.name || '');
    },
    onSpecChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setExecutorPoolSpec((option.key as string) || '');
    },
    onChangeMaxExecutors = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) setMaxExecutors(parseInt(newValue));
      if (!newValue) setMaxExecutors(0);
    },
    onChangeMaxQueueSize = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) setMaxQueueSizePerExecutor(parseInt(newValue));
      if (!newValue) setMaxQueueSizePerExecutor(0);
    };

  React.useEffect(() => {
    if (!ACTIVE_WORKSPACE_NAME) return;
    // TODO: Cleanup running requests on unmount.
    if (ACTIVE_WORKSPACE_NAME) void fetchExecutorPoolSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    setCurrentSchema(poolSpecOptions.find((option) => option.key === executorPoolSpec)?.executorPoolSpecSchema);
  }, [executorPoolSpec, poolSpecOptions]);

  return (
    <NavigationWrapper>
      <Header
        customPageTitle={`${!permissions.canEditRunnables ? 'View' : isNew ? 'Create' : 'Update'} executor pool`}
      />
      <Panel
        isLightDismiss
        hasCloseButton
        customWidth={'500px'}
        type={PanelType.custom}
        isFooterAtBottom={false}
        isOpen={isSpecDialogOpen}
        styles={{ main: { top: 96 } }}
        onDismiss={() => setIsSpecDialogOpen(false)}
      >
        {currentSchema ? (
          <>
            <Form
              validator={validator}
              schema={currentSchema}
              formData={executorPoolConfig}
              onSubmit={(schema) => {
                setExecutorPoolConfig(schema.formData);
                setIsSpecDialogOpen(false);
              }}
            >
              <Button text="Save" styles={buttonStylesPrimary} type="submit" />
            </Form>
          </>
        ) : undefined}
      </Panel>
      <div className={classNames.form}>
        <TextField
          required
          label="Name"
          defaultValue={displayName}
          className={classNames.dialogInput}
          readOnly={!permissions.canEditRunnables}
          onChange={(_ev, newValue) => setDisplayName(newValue || '')}
          errorMessage={showExecutorPoolValidation && !displayName ? 'This field cannot be empty.' : ''}
        />
        <Dropdown
          required
          label="Executor pool specification"
          options={poolSpecOptions}
          onChange={onSpecChange}
          defaultSelectedKey={executorPoolSpec}
          className={classNames.dialogInput}
          disabled={!permissions.canEditRunnables}
          errorMessage={showExecutorPoolValidation && !executorPoolSpec ? 'This field cannot be empty.' : ''}
        />
        {executorPoolSpec ? (
          <>
            <Label required>Config</Label>
            <div className={classNames.editorContainer}>
              <Editor
                minimal
                language="json"
                readOnly={!permissions.canEditRunnables}
                defaultCode={JSON.stringify(executorPoolConfig, null, 2)}
                onCodeChange={(newValue) => setExecutorPoolConfig(JSON.parse(newValue || '{}'))}
                error={
                  showExecutorPoolValidation
                    ? !executorPoolConfig
                      ? 'This field cannot be empty.'
                      : currentSchema && !validator.isValid(currentSchema, executorPoolConfig, {})
                      ? 'Invalid schema, try using config form editor.'
                      : ''
                    : ''
                }
              />
            </div>
            {permissions.canEditRunnables ? (
              <Button
                text="Edit config in form editor"
                onClick={() => setIsSpecDialogOpen(true)}
                className={classNames.editorButton}
                styles={buttonStylesGhost}
              />
            ) : null}
          </>
        ) : null}
        <div className={classNames.expandedContentItem}>
          <TextField
            type="number"
            label="Max executors"
            onChange={onChangeMaxExecutors}
            defaultValue={`${maxExecutors}`}
            className={classNames.dialogInput}
            styles={{ field: { paddingRight: 0 } }}
            readOnly={!permissions.canEditRunnables}
            aria-labelledby="max-exec-field"
            onRenderLabel={(props?: ITextFieldProps) => (
              <div id={'max-exec-field'} className={classNames.infoLabel}>
                {props?.label}
                <Info isTooltip className={classNames.tooltip}>
                  How many executors can be created in this pool. If not specified, executors limit is infinity.
                </Info>
              </div>
            )}
          />
        </div>
        <div className={classNames.expandedContentItem}>
          <TextField
            type="number"
            label="Max queue size per executor"
            defaultValue={`${maxQueueSizePerExecutor}`}
            onChange={onChangeMaxQueueSize}
            className={classNames.dialogInput}
            styles={{ field: { paddingRight: 0 } }}
            aria-labelledby="max-queue-field"
            readOnly={!permissions.canEditRunnables}
            onRenderLabel={(props?: ITextFieldProps) => (
              <div id={'max-queue-field'} className={classNames.infoLabel}>
                {props?.label}
                <Info isTooltip className={classNames.tooltip}>
                  New executor will be created every time when queue size reach this limit. If size is not set, only one
                  executor will be created for this pool.
                </Info>
              </div>
            )}
          />
        </div>
        {permissions.canEditRunnables ? (
          <Button
            onClick={onActionClick}
            styles={buttonStylesPrimary}
            text={isNew ? 'Create' : 'Update'}
            className={classNames.footerButton}
          />
        ) : null}
      </div>
    </NavigationWrapper>
  );
};

export default ExecutorPoolDetail;
